import React from "react";
import { observer } from "mobx-react";
import { TimeOfDayHeading } from "../TimeOfDayHeading";
import { WeeklyScheduleTimeOfDayGrid } from "../WeeklyScheduleTimeOfDayGrid";
import { WeeklyScheduleGridContainerProps } from "../../WeeklyScheduleGridContainer";

export interface TimeOfDayContainerProps extends Partial<WeeklyScheduleGridContainerProps> {
  tod: number;
}

export const TimeOfDayContainer: React.FC<TimeOfDayContainerProps> = observer(
  ({
     tod,
     neighbourhoodSets,
     getBlockIsSelected,
     getBlockIsAvailable,
     getBlockColor,
     getBlockNeighbourhoodSetIndex,
     getBlockCoverText,
     getBlockShowDeselectButton,
     onSelectBlock,
     onDeselectBlock,
     onSetAvailability,
     onOpenMultiSetPopUp,
     onBlockCoverTextClick
   }) => (
    <div className="timeOfDayContainer">
      <TimeOfDayHeading tod={tod} />
      <WeeklyScheduleTimeOfDayGrid
        tod={tod}
        neighbourhoodSets={neighbourhoodSets}
        getBlockIsSelected={getBlockIsSelected}
        getBlockIsAvailable={getBlockIsAvailable}
        getBlockColor={getBlockColor}
        getBlockNeighbourhoodSetIndex={getBlockNeighbourhoodSetIndex}
        getBlockCoverText={getBlockCoverText}
        getBlockShowDeselectButton={getBlockShowDeselectButton}
        onSelectBlock={onSelectBlock}
        onDeselectBlock={onDeselectBlock}
        onSetAvailability={onSetAvailability}
        onOpenMultiSetPopUp={onOpenMultiSetPopUp}
        onBlockCoverTextClick={onBlockCoverTextClick}
      />
    </div>
  )
);
