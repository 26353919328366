import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component";
import { Theme, useTheme } from "@material-ui/core/styles";
import { Box, Chip, FormControl, InputLabel, MenuItem, OutlinedInput, Select } from "@material-ui/core";

import styles from "./styles.css";

const ITEM_HEIGHT = 48;
const ITEM_PADDING_TOP = 8;
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 300,
    },
  },
};

const getStyles = (
  neighbourhood: string,
  localAreaNames: readonly string[],
  theme: Theme
) => {
  return {
    fontWeight:
      localAreaNames.indexOf(neighbourhood) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
};

export interface MultipleNeighbourhoodSelectorProps {
  disabled?: boolean;
  color?: string;
  availableLocalAreaNames: string[];
  selectedLocalAreaNames: string[];
  onSelectLocalAreaNames: (localAreaNames: string[]) => void;
}

export const MultipleNeighbourhoodSelector: React.FC<MultipleNeighbourhoodSelectorProps> =
  observer(({ disabled, color, availableLocalAreaNames, selectedLocalAreaNames, onSelectLocalAreaNames }) => {
    const theme = useTheme();

    const handleChange = (event: React.ChangeEvent<{ value: string[] }>) => {
      const localAreaNames = event.target.value;
      onSelectLocalAreaNames(localAreaNames);
    };

    const coloredInputStyle = `.multipleNeighbourhoodSelector > * > .MuiFormLabel-root.Mui-focused { color: ${color} !important }`
    const coloredBorderStyle = `.multipleNeighbourhoodSelector > * > .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline { border-color: ${color} !important }`

    return (
      <Styled styles={styles}>
        <div className="flex justify-content-center align-items-center multipleNeighbourhoodSelector">
          <style>{coloredInputStyle + "\n" + coloredBorderStyle}</style>
          <FormControl variant="outlined" className="multipleNeighbourhoodSelectorFormControl">
            <InputLabel id="multipleNeighbourhoodSelectorLabel">Local Areas</InputLabel>
            <Select
              autoWidth
              labelId="multipleNeighbourhoodSelectorLabel"
              input={<OutlinedInput label="Local Areas" />}
              multiple
              disabled={disabled}
              value={selectedLocalAreaNames}
              onChange={handleChange}
              renderValue={(selected: string[]) => (
                <Box className="flex multipleNeighbourhoodSelectorChips">
                  {selected.map((value) => (
                    <Chip key={value} label={value} className="multipleNeighbourhoodSelectorChip" />
                  ))}
                </Box>
              )}
              MenuProps={MenuProps}
            >
              {availableLocalAreaNames.map((selectLocalAreaItem) => (
                <MenuItem
                  key={selectLocalAreaItem}
                  value={selectLocalAreaItem}
                  style={getStyles(selectLocalAreaItem, selectedLocalAreaNames, theme)}
                >
                  {selectLocalAreaItem}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      </Styled>
    );
  });
