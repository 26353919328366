import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component";

import styles from "./styles.css";
import { Button } from "@material-ui/core";

export interface NeighbourhoodSetButtonProps {
  id: any;
  isAvailable: boolean;
  color: string;
  onClick: (event: any) => void;
}

export const NeighbourhoodSetButton: React.FC<NeighbourhoodSetButtonProps> =
  observer(({ id, isAvailable, color, onClick }) => (
    <Styled styles={styles}>
      <div className={`${id} neighbourhoodSetButton ${isAvailable ? "available" : ""}`}>
        <style>{`.${id} > .MuiButtonBase-root { background-color: ${color} !important }`}</style>
        <Button
          variant="contained"
          size="small"
          onClick={onClick}
        >
          &nbsp;
        </Button>
      </div>
    </Styled>
  ));
