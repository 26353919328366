import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import { Styled } from "direflow-component";

import styles from "./styles.css";
import { DeselectButton } from "./DeselectButton";
import { SetAvailableSingleBlockToggle } from "./SetAvailableSingleBlockToggle";
import { GridBlockProps } from "../GridBlock";
import { NeighbourhoodSetButton } from "./NeighbourhoodSetButton";

export interface AvailabilityBlockContainerProps extends Omit<Partial<GridBlockProps>, "onSetAvailability" | "onSelectNeighbourhoodSet"> {
  keyStr: string;
  isAvailable: boolean;
  neighbourhoodSetIndex: number;
  onSetAvailability: (isAvailable: boolean) => void;
  onOpenMultiSetPopUp: () => void;
}

export const AvailabilityBlockContainer: React.FC<AvailabilityBlockContainerProps> = observer(
  ({
     keyStr,
     isAvailable,
     neighbourhoodSets,
     neighbourhoodSetIndex,
     onDeselectBlock,
     onSetAvailability,
     onOpenMultiSetPopUp
   }) => {
    const currentNeighbourhoodSet = neighbourhoodSets[neighbourhoodSetIndex];
    const color = currentNeighbourhoodSet?.color;
    return (
      <Styled styles={styles}>
        <div className="flex column justify-content-evenly align-items-center availabilityBlockContainer">
          <DeselectButton onClick={onDeselectBlock} />
          <Typography color={isAvailable ? "secondary" : undefined}>
            Available
          </Typography>
          <SetAvailableSingleBlockToggle
            isAvailable={isAvailable}
            onSetAvailability={onSetAvailability}
          />
          <NeighbourhoodSetButton
            id={`n-${keyStr}`}
            isAvailable={isAvailable}
            color={color}
            onClick={onOpenMultiSetPopUp}
          />
        </div>
      </Styled>
    );
  });