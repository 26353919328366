import React, { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { SettingMultipleBlocksHeading } from "./SettingMultipleBlocksHeading";
import { WeeklyScheduleGridContainerProps } from "../WeeklyScheduleGridContainer";
import { SetAvailableMultipleBlocksToggle } from "./SettingMultipleBlocksMain/SetAvailableMultipleBlocksToggle";
import styles from "./styles.css";
import { Styled } from "direflow-component";
import { getBlockNameSimple } from "../../../utils/grid-utilities";

export interface SettingMultipleBlocksContainerProps extends Partial<WeeklyScheduleGridContainerProps> {
  isOpen: boolean;
  handleCloseMultiSetPopUp: () => void;
}

export const SettingMultipleBlocksContainer: React.FC<SettingMultipleBlocksContainerProps> =
  observer(
    ({
       isOpen,
       selectedBlocks,
       neighbourhoodSets,
       getBlockIsAvailable,
       getBlockNeighbourhoodSetIndex,
       onDeselectAllBlocks,
       onSetMultipleBlocksAvailability,
       onSetMultipleBlocksNeighbourhoodSet,
       onOverrideMapNeighbourhoodIndex,
       handleCloseMultiSetPopUp,
     }) => {
      const [renderButtons, setRenderButtons] = useState(false);
      const singleBlockName = selectedBlocks.length === 1 && getBlockNameSimple(selectedBlocks[0]).toUpperCase();

      useEffect(() => {
        isOpen
          ? setRenderButtons(true)
          : setTimeout(() => setRenderButtons(false), 300)
      }, [renderButtons, isOpen]);

      return (
        <Styled styles={styles}>
          <div className={`flex justify-content-center align-items-center settingMultipleBlocksContainer ${isOpen ? "open" : ""}`}>
            <div className="settingMultipleBlocksBackdrop" onClick={handleCloseMultiSetPopUp} />
            <div className="flex column justify-content-between settingMultipleBlocksContent">
              <SettingMultipleBlocksHeading blockName={singleBlockName} />
              {renderButtons && (
                <SetAvailableMultipleBlocksToggle
                  selectedBlocks={selectedBlocks}
                  neighbourhoodSets={neighbourhoodSets}
                  getBlockIsAvailable={getBlockIsAvailable}
                  getBlockNeighbourhoodSetIndex={getBlockNeighbourhoodSetIndex}
                  onDeselectAllBlocks={onDeselectAllBlocks}
                  handleCloseMultiSetPopUp={handleCloseMultiSetPopUp}
                  onSetMultipleBlocksAvailability={onSetMultipleBlocksAvailability}
                  onSetMultipleBlocksNeighbourhoodSet={onSetMultipleBlocksNeighbourhoodSet}
                  onOverrideMapNeighbourhoodIndex={onOverrideMapNeighbourhoodIndex}
                />
              )}
            </div>
          </div>
        </Styled>
      );
    }
  );