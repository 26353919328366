import React from "react";
import { observer } from "mobx-react";
import styles from "./styles.css";
import { Styled } from "direflow-component";
import { Typography } from "@material-ui/core";

export interface ColorCircleProps {
  text?: string;
  disabled?: boolean;
  isSelected: boolean;
  color: string;
  onClick: (event: React.MouseEvent<HTMLElement>) => void;
}

export const NeighbourhoodSetIndicatorColor: React.FC<ColorCircleProps> =
  observer(({ text, disabled, isSelected, color, onClick}) => (
    <Styled styles={styles}>
      <div
        className={`relative flex justify-content-center align-items-center neighbourhoodSetIndicatorColor ${disabled ? "noEvents" : ""} ${isSelected ? "selected" : ""}`}
        style={{ background: color }}
        onClick={onClick}
      >
        {text && <Typography className="font-xs">{text}</Typography>}
      </div>
    </Styled>
  ));
