import { Controller } from "../../lib/controller";
import { Group } from "../../lib/types/dataTypes";
import { mcbBridge } from "./index";
import { FormLegacy } from "./form.legacy";

export interface GlobalState {
  unreadCounters: { [key: number]: number };
}

export interface State extends Controller<GlobalState> {
  /** Profile detail modal **/
  mcbListingProfileIsEmbedded: boolean;
  mcbListingProfileIsOpen: boolean;
  openMcbListingProfile: (groupId?: number) => void;
  closeMcbListingProfile: () => void;


  /** Sign in modal **/
  mcbSignInFormOpen: boolean;


  /** CAF-use mcb-market-results-popup states **/
  mcbMarketResultsPopupIsOpen: boolean;
  mcbMarketResultsPopupGroups: Group[];
  openMarketResultsPopup: (searchResults: Group[]) => void;
  closeMarketResultsPopup: () => void;
  addMarketResultsPopupSaveHandler: (name: string, method: (event: any, profileId: number) => any) => any;
  getMarketResultsPopupProfileSaveLoading: (method: ((profileId: number) => boolean)) => any;
  setMarketResultsPopupSavedProfileIds: (profileIds: number[]) => any;
  marketResultsPopupChatHandler: (profileId: number) => any;
  setMarketResultsPopupChatHandler: (method: ((profileId: number) => any)) => any;

  /** CAF-use SetAvailableMultipleBlocksToggle Apply button **/
  multipleBlocksApplyButton: HTMLButtonElement;

  /** CAF-use mcb-market-criteria-form states **/
  mcbMarketCriteriaFormUseMobile: boolean;
  setMarketCriteriaFormUseMobile: (isMobile: boolean) => boolean;

  /** For CAF 2.0 use group context **/
  finderContextGroupId: number;
  clearFinderContextGroupId: () => void;
  setFinderContextGroup: (groupId: number) => void;

  /** Custom FormLegacy instance to be used in criteria form web-component **/
  customSearchForm: FormLegacy;
  setCustomSearchForm: (searchForm: FormLegacy) => FormLegacy;
}

export let stateCtrl: State = {} as State;
export const initStateCtrl = () => mcbBridge.isInitialized().then(() => stateCtrl = mcbBridge.getStateCtrl());