import { NeighbourhoodSet } from "../lib/types/caf/cafProfileTypes";
import { theme } from "./styles/theme";

export const defaultCity = "Vancouver, BC, Canada";

export const vancouver = "Vancouver"; 

export const timeOfDayName = [
  "Morning",
  "Afternoon",
  "Evening",
  "Overnight",
];

export const timeOfDayDescription = [
  "6 AM to 12 PM",
  "12 PM to 6 PM",
  "6 PM to 10 PM",
  "10 PM to 6 AM",
];

export const TimeBlockTimeStringMap = [
  ["06:00:00", "12:00:00"],
  ["12:00:00", "18:00:00"],
  ["18:00:00", "22:00:00"],
  ["22:00:00", "23:59:00"]
];

export const numOfPossibleTimeSlotsInADay = 4;

export const showWeekOfDay = [
  "Sunday",
  "Monday",
  "Tuesday",
  "Wednesday",
  "Thursday",
  "Friday",
  "Saturday",
];

export const defaultNeighbourhoodSetIndex = 0;
export const defaultNeighbourhoodSetColor = theme.palette.secondary.main;

export const defaultNeighbourhoodSetData: NeighbourhoodSet = {
  color: defaultNeighbourhoodSetColor,
  // TODO: For simulation, should eventually empty neighbourhoodIds replace with municipality sync
  neighbourhoodIds: [11, 19],
};
export const neighbourhoodSetPallet = [
  "#3C6464",
  "#3C3CAB",
  "#7F3C7F",
  "#8B453C",
  "#8A3CE2",
  "#B03C60",
  "#556B3C",
  "#3C803C",
  "#5D6CC6",
  "#E13C3C",
  "#4676B4",
  "#696969",
  "#9670FF",
  "#DC643C",
  "#FF4693",
  "#3C90FF",
  "#B8863C",
  "#FA8072",
  "#C88BC8",
  "#7FFF3C",
  "#3CC896",
  "#CDDC3C",
  "#FFFF3C",
  "#FFA178",
  "#8FBC8F",
  "#3CE1FF",
  "#FFB6C1",
  "#DEB887",
  "#ADD8E6"
];

export const unselectedCustomSetBlocksAlertMsg = (blocks: string) =>
  `Would you like to apply your neighbourhood selection changes on the map to all time blocks associated with this one?\n\n${blocks}`;

export const popupSelectCustomSetNeighbourhoodAlertMsg = (blocks: string) =>
  `Would you like to apply your neighbourhood selection changes immediately?

Choose YES if you would like your neighbourhood selection changes to apply to the selected time block(s)
as well as the following associated time block(s):
  
${blocks}
   
Choose NO if you would like to create a separate neighbourhood set just for the selected time block(s);
then choose APPLY in the pop-up box before making your neighbourhood selection changes.`;

export const unavailableBlocksSetNeighbourhoodAlertMsg = () =>
  `To change the neighbourhoods for the selected neighbourhood set, APPLY your availability setting change first.`;

export const maximumAllowedNeighbourhoodSets = numOfPossibleTimeSlotsInADay * showWeekOfDay.length + 1;

export const trainingVideoLink = "https://app.mycarebase.com/static/Caregiver_Availability_Finder_Training.mp4";

export const typeClassIds = {
  personalProfile: {
    v1: { id: 0, version: 1 }
  },
  caregiverProfile: {
    v1: { id: 1, version: 1 },
    v2: { id: 6, version: 2 },
    v3: { id: 10, version: 3 }
  },
  careReceiverProfile: {
    v1: { id: 2, version: 1 },
    v2: { id: 7, version: 2 },
    v3: { id: 9, version: 3 }
  },
  familyMemberProfile: {
    v1: { id: 3, version: 1 }
  },
  paidCaregiverProfile: {
    v1: { id: 29, version: 1 }
  },

  shortlistTopic: {
    v1: { id: 4, version: 1 }
  },
  messagingTopic: {
    v1: { id: 8, version: 1 }
  },
  candidateTopic: {
    v1: { id: 5, version: 1 },
    v2: { id: 11, version: 2 }
  },
  issueTopic: {
    v1: { id: 30, version: 1 }
  },
  shiftTemplateTopic: {
    v1: { id: 28, version: 1 }
  },
  leadTopic: {
    v1: { id: 39, version: 1 }
  }
};

export const groupTypeIds = {
  caregiver: 3,
  careReceiver: 2,
  get careCircle() {
    return groupTypeIds.careReceiver;
  },
  household: 7,
  cleaner: 8,
  handyman: 9,
  transportation: 10,
  myCareBaseStaff: 5,
};

export const RoleGroupGroupTypeIds = [
  groupTypeIds.caregiver,
  groupTypeIds.cleaner,
  groupTypeIds.handyman,
  groupTypeIds.transportation,
];

export const topicTypeIds = {
  shortlist: 8,
  candidate: 9,
  groupMessaging: 7,
  messaging: 10,

  timesheet: 11,
  shift: 2,
  task: 3,
  note: 4,
  issue: 5,

  mCBBroadcasts: 12,
  proactiveChat: 13,

  onboarding: 14,
  lead: 15
};


// ![#696969](https://via.placeholder.com/15/696969/000000?text=+) `#696969`
// ![#556b2f](https://via.placeholder.com/15/556b2f/000000?text=+) `#556b2f`
// ![#8b4513](https://via.placeholder.com/15/8b4513/000000?text=+) `#8b4513`
// ![#5d6cc6](https://via.placeholder.com/15/5d6cc6/000000?text=+) `#5d6cc6`
// ![#008000](https://via.placeholder.com/15/008000/000000?text=+) `#008000`
// ![#b8860b](https://via.placeholder.com/15/b8860b/000000?text=+) `#b8860b`
// ![#008b8b](https://via.placeholder.com/15/008b8b/000000?text=+) `#008b8b`
// ![#4682b4](https://via.placeholder.com/15/4682b4/000000?text=+) `#4682b4`
// ![#9acd32](https://via.placeholder.com/15/9acd32/000000?text=+) `#9acd32`
// ![#00008b](https://via.placeholder.com/15/00008b/000000?text=+) `#00008b`
// ![#7f007f](https://via.placeholder.com/15/7f007f/000000?text=+) `#7f007f`
// ![#8fbc8f](https://via.placeholder.com/15/8fbc8f/000000?text=+) `#8fbc8f`
// ![#b03060](https://via.placeholder.com/15/b03060/000000?text=+) `#b03060`
// ![#ff0000](https://via.placeholder.com/15/ff0000/000000?text=+) `#ff0000`
// ![#ffff00](https://via.placeholder.com/15/ffff00/000000?text=+) `#ffff00`
// ![#deb887](https://via.placeholder.com/15/deb887/000000?text=+) `#deb887`
// ![#7fff00](https://via.placeholder.com/15/7fff00/000000?text=+) `#7fff00`
// ![#8a2be2](https://via.placeholder.com/15/8a2be2/000000?text=+) `#8a2be2`
// ![#00ff7f](https://via.placeholder.com/15/00ff7f/000000?text=+) `#00ff7f`
// ![#dc143c](https://via.placeholder.com/15/dc143c/000000?text=+) `#dc143c`
// ![#3ce1e1](https://via.placeholder.com/15/3ce1e1/000000?text=+) `#3ce1e1`
// ![#3c3ce1](https://via.placeholder.com/15/3c3ce1/000000?text=+) `#3c3ce1`
// ![#da70d6](https://via.placeholder.com/15/da70d6/000000?text=+) `#da70d6`
// ![#ff00ff](https://via.placeholder.com/15/ff00ff/000000?text=+) `#ff00ff`
// ![#1e90ff](https://via.placeholder.com/15/1e90ff/000000?text=+) `#1e90ff`
// ![#fa8072](https://via.placeholder.com/15/fa8072/000000?text=+) `#fa8072`
// ![#add8e6](https://via.placeholder.com/15/add8e6/000000?text=+) `#add8e6`
// ![#ff1493](https://via.placeholder.com/15/ff1493/000000?text=+) `#ff1493`
// ![#ffb6c1](https://via.placeholder.com/15/ffb6c1/000000?text=+) `#ffb6c1`
