import React from "react";
import { observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";

export interface ViewResultListButtonProps {
  count: number;
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  onClick: (event: any) => void;
}

export const ViewResultListButton: React.FC<ViewResultListButtonProps> =
  observer(({ count, disabled, size, onClick }) => {
    return (
      <Button
        variant="contained"
        size={size || "medium"}
        disabled={disabled}
        onClick={onClick}
        color={disabled ? "secondary" : "primary"}
        className="viewResultListButton"
      >
        <Typography>
          View {count} {count > 1 ? "caregivers" : "caregiver"} matching all selections
        </Typography>
      </Button>
    );
  });
