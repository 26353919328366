import { Controller } from "../../lib/controller";
import { mcbBridge } from "./index";
import { Group, Profile } from "../../lib/types/dataTypes";
import { groupTypeIds } from "../../config/constants";
import { Topic } from "../../lib/types/topicTypes";
import { AggregatedRatingReviewResponseDTO, SearchResult } from "../../lib/types/mcb/dataTypes";
import { FormLegacy } from "./form.legacy";
import { JSONString } from "../../lib/types/miscTypes";

export interface McbSearchStore {
  selectedService: Group["typeId"];
  viewGroupId: Profile["id"];
  searchFormDataQueue: { [key: number]: Profile["data"] }; // key is groupTypeId
}

export interface McbSearchController extends Controller<McbSearchStore> {
  ready: boolean;
  geoLoading: boolean;
  searching: boolean;
  isCheckingShortlists;
  isCheckingCandidates: boolean;

  careCircleDataRestored: boolean;
  householdDataRestored: boolean;

  availableServices: { placeholder: string; name: number; }[];

  caregiverForm: FormLegacy;
  careCircleSearchForm: FormLegacy & { groupId?: number };
  householdSearchForm: FormLegacy & { groupId?: number };

  searchResults: SearchResult[];
  candidateGroups: Group[];

  ratingLoading: boolean;
  aggregatedRatings: AggregatedRatingReviewResponseDTO[];

  readonly selectedService: typeof groupTypeIds[keyof typeof groupTypeIds];
  readonly viewGroupId: number;
  readonly searchFormDataQueue: McbSearchStore["searchFormDataQueue"];
  readonly searchForm: FormLegacy & { groupId?: number };
  readonly careCircleScratchpad: Group;
  readonly householdScratchpad: Group;
  readonly scratchpadGroup: Group;
  readonly shortlistTopics: Topic[];
  readonly shortlistTopic: Topic;
  readonly shortlistingAvailable: boolean;
  readonly allResultGroups: SearchResult[] | Group[];
  readonly candidateTopicGroups: Group[];
  readonly candidateTopics: Topic[];

  getSearchFields: () => Promise<JSONString>;
  
  detectLocation: () => Promise<void>;

  setService: (service: typeof groupTypeIds[keyof typeof groupTypeIds]) => void;

  setViewGroupId: (id: Group["id"]) => number;

  searchListings: (noSave?: boolean) => Promise<void>;

  getProfileAvatarUri: (profile: Profile) => string;

  getProfileAbilities: (profile: Profile) => string[];

  getProfileLanguages: (profile: Profile) => string[];

  addToShortlist: (groupId: number) => Promise<void>;

  removeFromShortlist: (topicId: Topic["id"]) => Promise<void>;

  candidateSyncReady: () => Promise<void[]>;

  findAggregatedRatingForProfile: (profileId: number) => AggregatedRatingReviewResponseDTO;

  findGroupRatingScore: (profile: Profile) => { samples: number; rating: number };

  checkMissingGroup: (clickedSearch: boolean, checkCareCircle?: boolean, checkHousehold?: boolean) => Promise<any>;
}

export let mcbSearchCtrl: McbSearchController = {} as McbSearchController;
export const initMcbSearchCtrl = () => mcbBridge.isInitialized().then(() => mcbSearchCtrl = mcbBridge.getMcbSearchCtrl());