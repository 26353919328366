import React from "react";
import { observer } from "mobx-react";
import { Button, Grid, Typography } from "@material-ui/core";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import { showWeekOfDay, timeOfDayDescription, timeOfDayName } from "../../../config/constants";
import { responsive } from "../../../config/styles/responsive";
import { AvailabilityBlockContainer } from "../AvailabilityBlockContainer";
import { WeeklyScheduleContainerProps } from "../../../lib/types/propTypes";
import { getKeyStrId } from "../../../utils/grid-utilities";
import { preventDefaultStopProp } from "../../../utils/helpers";
import { DeselectButton } from "../AvailabilityBlockContainer/DeselectButton";

export interface GridBlockProps extends Partial<WeeklyScheduleContainerProps> {
  tod: number;
  dow: number;
}

export const GridBlock: React.FC<GridBlockProps> = observer(
  ({
     tod,
     dow,
     neighbourhoodSets,
     getBlockIsSelected,
     getBlockIsAvailable,
     getBlockColor,
     getBlockNeighbourhoodSetIndex,
     getBlockCoverText,
     getBlockShowDeselectButton,
     onSelectBlock,
     onDeselectBlock,
     onSetAvailability,
     onOpenMultiSetPopUp,
     onBlockCoverTextClick
   }) => {
    const keyStr = getKeyStrId(dow, tod);
    const isHeaderRow = tod === -1;
    const isStartingCol = dow === -1;
    const isStaticBlock = isHeaderRow || isStartingCol;
    const isAvailable = getBlockIsAvailable(keyStr);
    const isEditorAndSelected = getBlockIsSelected(keyStr);
    const color = getBlockColor(keyStr);
    const neighbourhoodSetIndex = getBlockNeighbourhoodSetIndex(keyStr);
    const blockCoverText = getBlockCoverText(keyStr);
    const blockCanBeClicked = blockCoverText && !["―", "0"].includes(blockCoverText);
    const showNonEditorDeselectButton = getBlockShowDeselectButton(keyStr);
    const handleDeselectBlock = (event: any) => {
      preventDefaultStopProp(event);
      return onDeselectBlock(keyStr);
    };
    const handleSetAvailability = (available: boolean) => onSetAvailability(keyStr, available);
    const handleBlockCoverTextClick = event => {
      preventDefaultStopProp(event);
      onBlockCoverTextClick(keyStr);
    };
    return (
      <Styled styles={styles}>
        {isStartingCol && responsive.deviceDimension.isMobile ? null : (
          <Grid
            container
            item
            xs={2}
            style={{ backgroundColor: isAvailable && color }}
            justifyContent="center"
            alignItems="center"
            className={`root-grid-size flex align-items-stretch justify-content-center weeklyScheduleAllDayGridBlock ${isStaticBlock ? "" : "pointer"} ${keyStr}`}
            onClick={e => !isEditorAndSelected && !isStaticBlock && onSelectBlock(keyStr)}
          >
            {isEditorAndSelected ? (
              <AvailabilityBlockContainer
                keyStr={keyStr}
                neighbourhoodSets={neighbourhoodSets}
                isAvailable={isAvailable}
                neighbourhoodSetIndex={neighbourhoodSetIndex}
                onDeselectBlock={handleDeselectBlock}
                onSetAvailability={handleSetAvailability}
                onOpenMultiSetPopUp={onOpenMultiSetPopUp}
              />
            ) : (
              <Grid
                container
                direction="column"
                justifyContent="center"
                alignItems="center"
                className="nonEditingContent relative"
              >
                {isHeaderRow ? ( // x = 0 row blocks
                  <Typography className="nonEditingBlockText" variant="body1">{showWeekOfDay[dow]}</Typography>
                ) : isStartingCol ? ( // y = 0 column blocks
                  <>
                    <Typography className="nonEditingBlockText" variant="body1">
                      {timeOfDayName[tod]}
                    </Typography>
                    <Typography className="nonEditingBlockText" variant="body1">
                      Anytime between
                    </Typography>
                    <Typography className="nonEditingBlockText" variant="body1">
                      {timeOfDayDescription[tod]}
                    </Typography>
                  </>
                ) : blockCoverText && (
                  <>
                    {showNonEditorDeselectButton && (
                      <div className="deselectButtonContainer absolute">
                        <DeselectButton onClick={handleDeselectBlock} />
                      </div>
                    )}
                    {blockCanBeClicked ? (
                      <Button
                        variant="contained"
                        color="secondary"
                        className="coverText textPre textBold textUnderline font-l"
                        onClick={handleBlockCoverTextClick}
                      >
                        {blockCoverText}
                      </Button>
                    ) : (
                      <Typography className="coverText textPre textBold font-l">
                        {blockCoverText}
                      </Typography>
                    )}
                  </>
                )}
              </Grid>
            )}
          </Grid>
        )}
      </Styled>
    );
  }
);
