import React from "react";
import { Observer, observer } from "mobx-react";
import { GoogleMap, Marker, Polygon, Rectangle } from "@react-google-maps/api";
import { action, computed, IObservableArray, observable, when } from "mobx";
import { CircularProgress } from "@material-ui/core";
import mapStyles, { mapPolygonMarkerLabelStyles, mapPolygonStyles, mapWithoutGeomPolygonMarkerLabelStyles } from "../../config/styles/map-styles";
import { computedFn } from "mobx-utils";
import { ui } from "../../client/mcb-bridge/ui";
import { placesApi } from "../../client/mcb-bridge/places";
import { mcbBridge } from "../../client/mcb-bridge";
import { findCityCoordinates, getDistanceInKms, getNeighbourhoodLocation, getNeighbourhoodPaths } from "../../utils/geo-utilities";
import { defaultCity } from "../../config/constants";
import { Coordinate, Neighbourhood, Radius } from "../../lib/types/caf/geoDataTypes";

type Map = google.maps.Map;
type MapOptions = google.maps.MapOptions;
type MapsPolygon = google.maps.Polygon;
type MapsRectangle = google.maps.Rectangle;
type MapsCircle = google.maps.Circle;
type CircleOptions = google.maps.CircleOptions;
type PolygonOptions = google.maps.PolygonOptions;
type MarkerOptions = google.maps.MarkerOptions;
type RectangleOptions = google.maps.RectangleOptions;
type RenderablePolygon = (PolygonOptions & { id: number });
type RenderablePolygonMarker = (MarkerOptions & { id: number });
type RenderableRectangle = (RectangleOptions & { id: number});
type RenderableCircle = (CircleOptions & { id: number});
type MapTypeId = google.maps.MapTypeId;

export interface ITestMapProps {}

@observer
class ITestMap extends React.Component<ITestMapProps> {
  map: Map;
  container: HTMLDivElement;

  readonly defaultZoom = 13;

  readonly neighbourhoodsRadius = 1500;

  readonly radiusMultiplier = 10;

  readonly mapOptions: Partial<MapOptions> = {
    clickableIcons: false,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: true,
    styles: mapStyles
  };

  readonly previewMapOptions: Partial<MapOptions> = {
    clickableIcons: false,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: false,
    zoomControl: false,
    zoom: 11,
    mapTypeControl: false,
    disableDoubleClickZoom: true,
    draggable: false,
    panControl: false,
    rotateControl: false,
    styles: mapStyles
  };

  @observable loading: boolean = true;
  @observable center: Coordinate = {} as Coordinate;
  @observable zoom: number;
  @observable mapTypeId: MapTypeId;
  @observable polygons: { [key: number]: MapsPolygon } = {};
  @observable rectangles: { [key: number]: MapsRectangle } = {};
  @observable circles: { [key: number]: MapsCircle } = {};
  @observable neighbourhoods: IObservableArray<Neighbourhood> = observable([]);

  constructor(props) {
    super(props);
    this.initialize();
  }

  @computed get neighbourhoodLabelMarkers(): RenderablePolygonMarker[] {
    const neighbourhoodLabelMarkers = this.neighbourhoods.map((neighbourhood: Neighbourhood) => {
      if(neighbourhood.geometry !== null && !(neighbourhood.municipality === neighbourhood.name)){
        return {
          id: neighbourhood.id,
          position: getNeighbourhoodLocation(neighbourhood),
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 0
          },
          clickable: true,
          label: {
            ...mapPolygonMarkerLabelStyles,
            color: this.mapTypeId === "hybrid" ? "#fff" : "#383434",
            fontSize: `${this.zoom * 1.3}px`,
            text: neighbourhood.name
          },
          draggable: false
        }
      }
      return undefined;
    });
    return neighbourhoodLabelMarkers.filter(neighbourhoodPolygon => neighbourhoodPolygon !== undefined);
  };

  @computed get neighbourhoodCircleMarkers(): RenderablePolygonMarker[] {
    const neighbourhoodCircleMarkers = this.neighbourhoods.map((neighbourhood: Neighbourhood) => {
      if(neighbourhood.geometry === null){
        return {
          id: neighbourhood.id,
          position: getNeighbourhoodLocation(neighbourhood),
          icon: {
            ...this.getPolygonFillProperties(neighbourhood),
            scale: 7*(this.zoom/this.defaultZoom)**5,
            path: google.maps.SymbolPath.CIRCLE,
          },
          clickable: true,
          draggable: false
        }
      }
      return undefined;
    });
    return neighbourhoodCircleMarkers.filter(neighbourhoodPolygon => neighbourhoodPolygon !== undefined);
  };

  @computed get municipalitiesAsNeighbourhoodLabelMarkers(): RenderablePolygonMarker[] {
    const neighbourhoodLabelMarkers = this.neighbourhoods.map((neighbourhood: Neighbourhood) => {
      if(neighbourhood.municipality === neighbourhood.name){
        return {
          id: neighbourhood.id,
          position: getNeighbourhoodLocation(neighbourhood),
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 0
          },
          clickable: true,
          label: {
            ...mapPolygonMarkerLabelStyles,
            color: this.mapTypeId === "hybrid" ? "#fff" : "#383434",
            fontSize: `${this.zoom * 1.3}px`,
            text: neighbourhood.name
          },
          draggable: false
        }
      }
      return undefined;
    });
    return neighbourhoodLabelMarkers.filter(neighbourhoodLabelMarker => neighbourhoodLabelMarker !== undefined);
  }

  @computed get neighbourhoodWithoutGeomLabelMarkers(): RenderablePolygonMarker[] {
    const neighbourhoodLabelMarkers = this.neighbourhoods.map((neighbourhood: Neighbourhood) => {
      if(neighbourhood.geometry === null){
        return {
          id: neighbourhood.id,
          position: getNeighbourhoodLocation(neighbourhood),
          icon: {
            path: google.maps.SymbolPath.CIRCLE,
            scale: 0
          },
          clickable: true,
          label: {
            ...mapWithoutGeomPolygonMarkerLabelStyles,
            color: this.mapTypeId === "hybrid" ? "#fff" : "#383434",
            fontSize: `${this.zoom * 1.3}px`,
            text: neighbourhood.name
          },
          draggable: false
        }
      }
      return undefined;
    });
    return neighbourhoodLabelMarkers.filter(neighbourhoodLabelMarker => neighbourhoodLabelMarker !== undefined);
  };

  @computed get neighbourhoodLabelRectangles(): RenderableRectangle[] {
    const neighbourhoodLabelRectangles = this.neighbourhoods.map((neighbourhood: Neighbourhood) => {
      if(neighbourhood.geometry === null){
        return {
          id: neighbourhood.id,
          ...this.getPolygonFillProperties(neighbourhood),
          clickable: true,
          bounds: {
            north: this.zoom <=17 ? Number(neighbourhood.location.coordinates[0]) + 0.001*(this.defaultZoom/this.zoom)**4 : Number(neighbourhood.location.coordinates[0]) + 0.001 * (this.defaultZoom/17)**4,
            south: this.zoom <=17 ? Number(neighbourhood.location.coordinates[0]) - 0.001*(this.defaultZoom/this.zoom)**4 : Number(neighbourhood.location.coordinates[0]) - 0.001 * (this.defaultZoom/17)**4,
            east:  this.zoom <=17 ? Number(neighbourhood.location.coordinates[1]) + 0.012*(this.defaultZoom/this.zoom)**4 : Number(neighbourhood.location.coordinates[1]) + 0.012 * (this.defaultZoom/17)**4,
            west:  this.zoom <=17 ? Number(neighbourhood.location.coordinates[1]) - 0.012*(this.defaultZoom/this.zoom)**4 : Number(neighbourhood.location.coordinates[1]) - 0.012 * (this.defaultZoom/17)**4,
          }
        }
      }
      return undefined;
    });
    return neighbourhoodLabelRectangles.filter(neighbourhoodPolygon => neighbourhoodPolygon !== undefined);
  };

  @computed get neighbourhoodLabelCircles(): RenderableCircle[] {
    const neighbourhoodLabelCircles = this.neighbourhoods.map((neighbourhood: Neighbourhood) => {
      if(neighbourhood.geometry === null){
        return {
          id: neighbourhood.id,
          ...this.getPolygonFillProperties(neighbourhood),
          clickable: true,
          center: getNeighbourhoodLocation(neighbourhood),
          radius: 250,
        }
      }
      return undefined;
    });
    return neighbourhoodLabelCircles.filter(neighbourhoodPolygon => neighbourhoodPolygon !== undefined);
  };

  @computed get neighbourhoodPolygons(): RenderablePolygon[] {
    const neighbourhoodPolygons =  this.neighbourhoods.map((neighbourhood: Neighbourhood) => {
      if(neighbourhood.geometry !== null){
        return {
          ...mapPolygonStyles,
          ...this.getPolygonFillProperties(neighbourhood),
          id: neighbourhood.id,
          clickable: true,
          paths: getNeighbourhoodPaths(neighbourhood)
        }
      }
      return undefined;
    });
    return neighbourhoodPolygons.filter(neighbourhoodPolygon => neighbourhoodPolygon !== undefined);
  };

  initialize = async () =>
    mcbBridge.isInitialized()
    .then(() => when(() => placesApi.initialized))
    .then(this.getCenter)
    .then(this.getNeighbourhoods)
    .then(() => this.loading = false)
    .catch(ui.showError);

  @action getCenter = async () => findCityCoordinates(defaultCity).then(coords => this.center = coords);

  @action getNeighbourhoods = async () => {
    return mcbBridge.getNeighbourhoodsData(this.center.lat, this.center.lng, this.neighbourhoodsRadius)
    .then(neighbourhoods => this.neighbourhoods.replace(neighbourhoods));
  }

  @action resetNeighbourhoods = async () => {
    const mapCenter:google.maps.LatLng =  this.map && this.map.getBounds() && this.map.getBounds().getCenter();
    const mapEdge:google.maps.LatLng = this.map && this.map.getBounds() && this.map.getBounds().getNorthEast();
    const viewRadius:Radius = mapCenter && mapEdge && getDistanceInKms(mapCenter,mapEdge);
    if (mapCenter && viewRadius){
      const neighbourhoods = await mcbBridge.getNeighbourhoodsData(mapCenter.lat(), mapCenter.lng(), this.radiusMultiplier*viewRadius);
      return this.neighbourhoods.replace(neighbourhoods);
    }
  }

  @action setMap = map => {
    this.map = map;
    this.zoom = this.map && this.map.getZoom();
  };

  @action setPolygon = (id: number, polygon: MapsPolygon) => this.polygons[id] = polygon;

  @action setRectangle = (id: number, rectangle: MapsRectangle) => this.rectangles[id] = rectangle;

  @action setCircle = (id: number,  circle: MapsCircle) => this.circles[id] = circle;

  @action removeCircle = (id: number) => !!this.circles[id] && delete this.circles[id];

  @action removePolygon = (id: number) => !!this.polygons[id] && delete this.polygons[id];

  @action removeRectangle = (id: number) => !!this.rectangles[id] && delete this.rectangles[id];

  getPolygonFillProperties = computedFn((neighbourhood: Neighbourhood) => {
    return {
      strokeColor: "#FF0000",
      strokeOpacity: 0.8,
      strokeWeight: 2,
      fillColor: "#FF0000"
    }
  });

  @action handleZoomChange = () => {
    const oldZoom = this.zoom;
    this.zoom = this.map && this.map.getZoom();
    if(oldZoom > this.zoom){
      this.resetNeighbourhoods();
    }
  }

  @action handleMapTypeChange = () => this.mapTypeId = this.map && this.map.getMapTypeId() as MapTypeId;

  renderNeighbourhoodLabels = () => <Observer>{() => this.zoom >= 13 && <>
    {this.neighbourhoodLabelMarkers.map(marker => (
      <Marker
        key={marker.id}
        position={marker.position}
        options={marker}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodCircles = () => <Observer>{() => this.zoom <= 12 && <>
    {this.neighbourhoodCircleMarkers.map(marker => (
      <Marker
        key={marker.id}
        position={marker.position}
        options={marker}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodWithoutGeomLabels = () => <Observer>{() => this.zoom >= 13 && <>
    {this.neighbourhoodWithoutGeomLabelMarkers.map(marker => (
      <Marker
        key={marker.id}
        position={marker.position}
        options={marker}
      />
    ))}
  </>}</Observer>;


  renderMunicipalitiesAsNeighbourhoods = () => <Observer>{() => this.zoom >= 12 && <>
    {this.municipalitiesAsNeighbourhoodLabelMarkers.map(marker => (
      <Marker
        key={marker.id}
        position={marker.position}
        options={marker}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodPolygons = () => <Observer>{() => <>
    {this.neighbourhoodPolygons.map(polygon => (
      <Polygon
        key={polygon.id}
        paths={polygon.paths}
        options={polygon}
        onLoad={p => this.setPolygon(polygon.id, p)}
        onUnmount={() => this.removePolygon(polygon.id)}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodRectangles = () => <Observer>{() => this.zoom >= 13 && <>
    {this.neighbourhoodLabelRectangles.map(rectangle => (
      <Rectangle
        key={rectangle.id}
        options={rectangle}
        onLoad={r => this.setRectangle(rectangle.id, r)}
        onUnmount={() => this.removeRectangle(rectangle.id)}
      />
    ))}
  </>}</Observer>;

  // renderNeighbourhoodCircles = () => <Observer>{() => this.zoom <= 12 && <>
  //   {this.neighbourhoodLabelCircles.map(circle => (
  //     <Circle
  //       key={circle.id}
  //       options={circle}
  //       center={circle.center}
  //       radius={circle.radius}
  //       onLoad={r => this.setCircle(circle.id, r)}
  //       onUnmount={() => this.removeCircle(circle.id)}
  //     />
  //   ))}
  // </>}</Observer>;

  render() {
    return <div className="flex column align-items-center mapContainer">
      {this.loading ? (
        <div className="flex align-items-center justify-content-center loadingContainer">
          <CircularProgress size={30} />
        </div>
      ) : (
        <GoogleMap
          mapContainerClassName="googleMap"
          onLoad={this.setMap}
          mapContainerStyle={{ width: "1280px", height: "656px" }}
          center={this.center}
          zoom={this.defaultZoom}
          options={this.mapOptions}
          onZoomChanged={this.handleZoomChange}
          onDragEnd = {this.resetNeighbourhoods}
          onMapTypeIdChanged={this.handleMapTypeChange}
        >
          {this.renderNeighbourhoodRectangles()}
          {this.renderNeighbourhoodCircles()}
          {this.renderNeighbourhoodWithoutGeomLabels()}
          {this.renderMunicipalitiesAsNeighbourhoods()}
          {this.renderNeighbourhoodLabels()}
          {this.renderNeighbourhoodPolygons()}
        </GoogleMap>
      )}
    </div>;
  };
}

export default ITestMap;