import { mcbBridge } from "./index";
import { AxiosInstance, AxiosRequestConfig, AxiosResponse, Method } from "axios";
import { ApiOptions } from "../../lib/types/miscTypes";
import { OAuth2Data } from "../../lib/types/dataTypes";
import { Controller } from "../../lib/controller";

export interface ApiStore {
  baseUrl?: string;
  noRenew?: boolean;
  xBaseSwitch?: string;
  deepRenew?: boolean;
  deepRenewExpiresIn?: number;
  lastActive?: number;
}

export interface Api extends Controller<ApiStore> {
  baseUrl: AxiosRequestConfig["baseURL"];
  pending: number;
  OAuth2Data: OAuth2Data;
  axios: AxiosInstance;

  readonly baseChanged: boolean;
  readonly xBaseSwitch: string;
  readonly noAutoRenew: boolean;
  readonly deepRenew: boolean;
  readonly deepRenewExpiresIn: number;
  readonly lastActive: number;

  async: (method: Method, overload1: string | ApiOptions, overload2?: ApiOptions) => Promise<AxiosResponse>;

  GET: (options: string | ApiOptions) => Promise<AxiosResponse>;

  POST: (options: string | ApiOptions) => Promise<AxiosResponse>;

  PATCH: (options: string | ApiOptions) => Promise<AxiosResponse>;

  PUT: (options: string | ApiOptions) => Promise<AxiosResponse>;

  DELETE: (options: string | ApiOptions) => Promise<AxiosResponse>;
}

export let api: Api = {} as Api;
export const initApi = () => mcbBridge.isInitialized().then(() => api = mcbBridge.getApi());