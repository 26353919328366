import { devServer } from './base';
import { env } from '../env';

const serviceRegistration = `${env === "debug" ? `https://${devServer}/registration` : "/registration"}`;
const serviceFormExt = `${env === "debug" ? `https://${devServer}/formExt` : "/formExt"}`;

export const endpointConfig = {
  // Maintenance status message.
  maintenance: `/maintenance_status`,

  // Before login
  register: `${serviceRegistration}/signup`,
  exists: (type, value) =>
    `${serviceRegistration}/signup/exist/${type}?value=${value}`,
  verify: `${serviceRegistration}/signup/verify`,

  // Login Sequence
  login: "/auth/oauth/token",
  post_login: "/user/user/post_login",
  user: "/user/user/me",
  resend_verification: `${serviceRegistration}/signup/resend_verify`,

  // Reset password
  reset_pass_request: `${serviceRegistration}/reset`,
  reset_pass_verify: `${serviceRegistration}/reset/verify`,
  reset_pass_exec: `${serviceRegistration}/reset/exec`,

  // Groups
  get_default_group: "/group/group/me/default",
  get_my_groups: "/group/group/me",
  group_by_id: (groupId, memberId) =>
    `/group/group/${groupId}?memberId=${memberId ? memberId : ""}`,
  create_group_profile: "/group/group/profile",
  groups_by_type_id: typeId => `/group/group/typeId/${typeId}`,
  group_types: typeId => `/group/groupType/${typeId ? typeId : ""}`,

  // Topics
  create_topic: "/topic",

  // Members
  get_default_member: "/group/member/me/default",
  get_my_members: "/group/member/me",
  get_members: "/group/member",
  member_by_id: memberId => `/group/member/${memberId}`,
  create_member: "/group/member",
  create_member_profile: "/group/member/profile",
  update_member_role: "/group/memberRole",
  members_by_group_id: groupId => `/group/member/groupId/${groupId}`,

  // Profiles
  profile_by_id: profileId => `group/profile/${profileId}`,
  profile_field_in_use: `${serviceFormExt}/profile/field`,
  update_profile_with_audit: id => `/groupExt/profile/withAudit/${id}`,

  // CAF
  get_neighbourhoods_data: (lat, lng, radius) => `/neighbourhood/byRadius?lat=${lat}&lng=${lng}&radius=${radius}`,
  get_caf_profile: (profileId) => `/CAFProfile/${profileId}`,
  // search_caf_marketplace: "/finderMarketplace/searchProfile",
  search_caf_marketplace: "/a/search",
  get_or_create_finder_shortlist: groupId =>
    `/topicExt/getOrCreateFinderShortlist?groupId=${groupId}`,

  geocoding: address => `/geoDistance/geocode/json?address=${address}`,
  reverse_geocoding: (lat, lon) => `/geoDistance/reverse/json?lat=${lat}&lon=${lon}&formatAddress=true`
};