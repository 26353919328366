import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component";
import { IconButton } from "@material-ui/core";
import { Close } from "@material-ui/icons";

import styles from "./styles.css";

export interface DeselectButtonProps {
  onClick: (event: any) => void;
}

export const DeselectButton: React.FC<DeselectButtonProps> = observer(
  ({ onClick }) => {
    return (
      <Styled styles={styles}>
        <IconButton
          className="iconButton deselectButton"
          onClick={onClick}
        >
          <Close fontSize="small" className="iconClose" />
        </IconButton>
      </Styled>
    );
  }
);
