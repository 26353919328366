import React from "react";
import { Observer, observer } from "mobx-react";
import { computed, observable } from "mobx";
import { Styled } from "direflow-component";
import $ from "jquery";
import styles from "./styles.css";
import { McbFinderController } from "./controller";
import { es6GetCurrURLQueryParams, getDisplayNameEng, isEmpty } from "../../utils/helpers";
import { mcbBridge } from "../../client/mcb-bridge";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import AvailabilityFinderContainer from "../../components/AvailabilityFinderContainer";
import { mcbSessionCtrl } from "../../client/mcb-bridge/session";
import { client } from "../../client/mcb-bridge/client";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import TrainingVideoModal from "../../components/TrainingVideoModal";
import { trainingVideoLink } from "../../config/constants";
import GroupSelector from "../../components/GroupSelector";

@observer
class McbFinder extends React.Component {
  controller: McbFinderController = {} as McbFinderController;

  @observable trainingVideoBoxVisible: boolean = false;

  finderInstructionElm: HTMLElement;
  finderInstructionRef = elm => this.finderInstructionElm = elm;

  @observable finderInstructionHoverHighlight: boolean = false;
  @observable finderInstructionClickHighlight: boolean = false;
  finderInstructionClickHighlightTimeout;

  constructor(props) {
    super(props);
    this.controller = new McbFinderController();
    // this.controller.disposers.push(
    //   reaction(() => [this.controller.profile, this.controller.mode], this.handleCafHeaderSpan)
    // );
    // setInterval(this.handleCafHeaderSpan, 1000);
    (window as any).cafOpenWebAppProfile = () => this.handleOpenWebAppProfile();
    // (window as any).cafFinderSwitchMode = () => this.controller.switchMode();
    this.fixH1HeaderZIndex();
  }

  // componentWillUnmount() {
  //   this.controller.cleanup();
  // }

  // @computed get profile(): Profile {
  //   return this.controller.profile;
  // };
  @computed get profileLoading(): boolean {
    return this.controller.appIsLoggedIn
      && (isEmpty(this.controller.userGroups) || !this.controller.searchGroupId);
  };
  @computed get loading(): boolean {
    if (this.profileLoading) return true;
    return isEmpty(this.controller.searchGroup);
  };
  @computed get showEditor(): boolean {
    // return this.controller.canShowEditor && this.controller.mode === "editor";
    return false;
  };

  @computed get finderInstructionHighlighted(): boolean {
    return this.finderInstructionHoverHighlight || this.finderInstructionClickHighlight;
  };

  get hideGroupSelector(): boolean {
    const { hideGroupSelector } = es6GetCurrURLQueryParams();
    return hideGroupSelector && hideGroupSelector === "true";
  };

  // handleCafHeaderSpan = async () => {
  //   await mcbBridge.isInitialized();
  //   const controlLinkStyles = `cursor: pointer; color: #543884; text-decoration: underline;`;
  //   const headerSpan = Array.from(document.getElementsByClassName("cafControlSpan"))[0];
  //   if (!headerSpan) return;
  //   const isLoggedIn = mcbSessionCtrl.appIsLoggedIn && client.credentialReady;
  //   const displayName = !isEmpty(this.controller.defaultProfile) ? `${getDisplayNameEng(this.controller.defaultProfile)}` : "...";
  //   const logoutLink = `<span style="${controlLinkStyles}" onclick="mcbSignOut()">Not <b>${displayName}</b>? Sign out.</span>`;
  //   const webAppLink = `<span style="${controlLinkStyles}" onclick="cafOpenWebAppProfile()">HERE</span>`;
  //   const webAppLinkLine = this.controller.canShowEditor ? `Click ${webAppLink} to edit your full profile.` : "";
  //   const modeSwitchLink = `<span style="${controlLinkStyles}" onclick="cafFinderSwitchMode()">HERE</span>`;
  //   const modeSwitchLine = (isLoggedIn && this.controller.canShowEditor)
  //     ? this.showEditor
  //       ? `Click ${modeSwitchLink} to go back to the Caregiver Finder.`
  //       : `Click ${modeSwitchLink} to edit your availability.`
  //     : "";
  //   headerSpan.innerHTML = isLoggedIn
  //     ? `Welcome, <b>${displayName}</b>!&nbsp;${logoutLink}<br/>${webAppLinkLine}<br/>${modeSwitchLine}`
  //     : `For Professional Caregivers who already have an account, please <span style="${controlLinkStyles}" onclick="showMcbSignIn()">SIGN IN</span> to edit your availability.`;
  // };

  handleOpenWebAppProfile = () => mcbBridge?.openWebApp();

  showMcbSignIn = () => mcbBridge.setLoginBoxVisible(true);

  showMcbTrainingVideo = () => this.trainingVideoBoxVisible = true;

  closeMcbTrainingVideo = () => this.trainingVideoBoxVisible = false;

  renderTrainingVideoComponent = () => <Observer>{() => (
    <TrainingVideoModal
      isModalOpen={this.trainingVideoBoxVisible}
      onModalClose={this.closeMcbTrainingVideo}
      url={trainingVideoLink}
    />
  )}</Observer>;

  fixH1HeaderZIndex = () => {
    const h1 = $(".x-section").find("h1.x-text-content-text-primary");
    if (isEmpty(h1[0])) return;
    let zIndexDiv = h1.parent(), i = 0;
    const foundZIndex = () => !!zIndexDiv[0] && Number(zIndexDiv[0]?.style.zIndex) >= 4;
    // eslint-disable-next-line
    while (!foundZIndex() && i < 10) {
      zIndexDiv = zIndexDiv.parent();
      i++;
    }
    if (!foundZIndex()) {
      console.warn("Failed to find H1 header to fix z-index within 10 iterations.");
      return;
    }
    return zIndexDiv.css("zIndex", "1");
  };

  hoverFinderInstructionHighlight = () => this.finderInstructionHoverHighlight = true;

  leaveFinderInstructionHighlight = () => this.finderInstructionHoverHighlight = false;

  clickFinderInstructionHighlight = () => {
    clearTimeout(this.finderInstructionClickHighlightTimeout);
    this.finderInstructionClickHighlight = true;
    this.finderInstructionClickHighlightTimeout = setTimeout(() => this.finderInstructionClickHighlight = false, 5000);
    return this.finderInstructionElm && this.finderInstructionElm.scrollIntoView({
      behavior: "smooth"
    });
  };

  renderControlSpan = () => {
    const isLoggedIn = mcbSessionCtrl.appIsLoggedIn && client.credentialReady;
    const mcbSignOut = (window as any).mcbSignOut;
    const displayName = !isEmpty(this.controller.defaultProfile) ? `${getDisplayNameEng(this.controller.defaultProfile)}` : "...";
    const logoutLink = <span className="controlLink" onClick={mcbSignOut}>Not <b>{displayName}</b>? Sign out.</span>;
    const webAppLink = <span className="controlLink" onClick={this.handleOpenWebAppProfile}>HERE</span>;
    const webAppLinkLine = this.showEditor ? <>Click {webAppLink} to edit your full profile.</> : "";
    // const modeSwitchLink = <span className="controlLink" onClick={this.controller.switchMode}>HERE</span>;
    // const modeSwitchLine = (isLoggedIn && this.controller.canShowEditor)
    //   ? this.showEditor
    //     ? <>Click {modeSwitchLink} to go back to the Caregiver Finder.</>
    //     : <>Click {modeSwitchLink} to edit your availability.</>
    //   : "";
    return <Observer>{() => <>{
      isLoggedIn
        ? <Typography className="font-s" gutterBottom>Welcome, <b>{displayName}</b>!&nbsp;{logoutLink}<br/>{webAppLinkLine}</Typography>
        // : <>For Professional Caregivers who already have an account, please <span className="controlLink" onClick={this.showMcbSignIn}>SIGN IN</span> to edit your availability.</>
        : <div className="flex align-items-center justify-content-between controlSpanButtons">
          <Button
            variant="contained"
            size="large"
            color="secondary"
            className="signIn"
            onClick={this.showMcbSignIn}
          >
            <Typography>
              I AM A PROFESSIONAL CAREGIVER LOOKING FOR WORK
            </Typography>
          </Button>
          <Button
            variant="contained"
            size="large"
            color="secondary"
            className="highlight"
            onMouseOver={this.hoverFinderInstructionHighlight}
            onMouseLeave={this.leaveFinderInstructionHighlight}
            onClick={this.clickFinderInstructionHighlight}
          >
            <Typography>
              I AM SEARCHING FOR A CAREGIVER TO HIRE
            </Typography>
          </Button>
        </div>
    }</>}</Observer>;
  };

  renderGroupSelector = () => <Observer>{() => {
    const { appIsLoggedIn, careCircleGroups, searchGroupId, setLoggedInUserSearchGroupId } = this.controller;
    if (!appIsLoggedIn) return null;
    if (isEmpty(careCircleGroups) || careCircleGroups.length <= 1) return null;
    if (this.hideGroupSelector) return null;
    return <div className="flex align-items-end finderGroupSelector">
      <Typography className="textBold font-s">
        Searching Caregiver(s) for&nbsp;
      </Typography>
      <GroupSelector
        groups={careCircleGroups}
        selectedGroupId={searchGroupId}
        onGroupChange={setLoggedInUserSearchGroupId}
      />
    </div>;
  }}</Observer>;

  render() {
    const {
      cityCoordinates,
      appIsLoggedIn,
      userGroups,
      searchGroup,
      // neighbourhoods,
      // updateProfile,
      // onMapZoomChange,
      // resetNeighbourhoods,
      setMapGlobal,
    } = this.controller;

    // console.log("Profile data", toJS((this.profile || {}).data))

    return (
      <McbComponentPastry>
        <Styled styles={styles}>
          <div className="mcbFinder container">
            <div className="instructions">
              {/*<div className="controlSpan">*/}
              {/*  {this.renderControlSpan()}*/}
              {/*</div>*/}
              {/*<br />*/}
              {/*<br />*/}
              {/*{!this.loading && this.showEditor && <>*/}
              {/*  <Typography className="videoInstruction font-s" gutterBottom>*/}
              {/*    Before reading the following instructions, check out the <span className="controlLink" onClick={this.showMcbTrainingVideo}>training video</span>!*/}
              {/*  </Typography>*/}
              {/*  <br />*/}
              {/*</>}*/}
              {this.renderTrainingVideoComponent()}
              {!this.loading && !this.showEditor && <>
                <Typography
                  ref={this.finderInstructionRef}
                  className={`finderInstruction font-s ${this.finderInstructionHighlighted ? "highlighted" : ""}`}
                  gutterBottom
                >
                  Start by entering a location or moving the map and select a point on the map. Then, scroll below the map and select the time blocks for which you are searching for care. You can also refine your search by providing more information about the care recipient and their needs.
                  {/*{ui.isMobile ? "If you are searching for a caregiver, start by tapping on the map to enter a location or move the map, and select the desired neighbourhood on the map. Then, press the top bar of the map screen to return to the main screen, scroll below the map, and select the time blocks for which you are searching for care."*/}
                  {/*             : "If you are searching for a caregiver, start by entering your location or by moving the map, and select the desired neighborhood on the map. Then, scroll below the map and select the time blocks for which you are searching for care." }*/}
                </Typography>
                <Typography className="finderInstruction font-s" gutterBottom>
                  Please note that the caregivers shown in the search results below only represent a portion of our full roster. Please <a href="https://mycarebase.com/contact-us/" target="_blank" rel="noopener noreferrer">contact us</a> to learn about other suitable candidates.
                </Typography>
              </>}
              {/*{!this.loading && this.showEditor && <Typography className="editorInstruction textPre font-s" gutterBottom>*/}
              {/*  1. Select the time blocks in which you are free to accept new clients. You do not have to be available for the whole time block—for example, if you are free to work between 3 PM and 6 PM, you should select the "12 PM to 6 PM" time block. Tap on the time block and tap on YES for availability. Repeat for each time block that you are free to accept work.<br />*/}
              {/*  <br />*/}
              {/*  2. Scroll down to the map and tap on the neighborhoods you are willing to commute to. They will turn colour as you select them. This just determines which opportunities we present to you based on the location of the client. When you are done with the neighborhood selections, go back up to the time block grid and tap on the little × in the top right corner of each box. This completes your selection process.<br />*/}
              {/*  <br />*/}
              {/*  3. If you have certain days or times where you have variations in the neighborhoods you can commute to—for example, on just Fridays you can work in West Van because you already have another client in that area on Fridays—repeat steps #1 and #2 and you can choose different neighborhoods for those days/times. You will see that the time block and the areas of the map that you choose are a different colour than your original selections.*/}
              {/*  <br />*/}
              {/*</Typography>}*/}
              {/*{!this.loading && this.showEditor && <Typography className="editorInstruction textPre font-s" gutterBottom>*/}
              {/*  <b>Note for first-time users:</b> When using the Caregiver Availability Finder for the first time, a Default Neighbourhood Set will be created for you based on the municipalities that you selected in your caregiver profile. To modify your Default Neighbourhood Set, press the "EDIT DEFAULT NEIGHBOURHOOD SET" button, then add or remove specific neighborhoods by selecting or deselecting them on the map.*/}
              {/*</Typography>}*/}
            </div>
            {this.loading ? (
              <div className="flex column align-items-center justify-content-center loadingContainer">
                <CircularProgress size={40} />
                {this.profileLoading && (
                  <Typography color="primary" style={{ marginTop: 10 }}>
                    Loading account data
                  </Typography>
                )}
              </div>
            ): <>
              {this.renderGroupSelector()}
              <AvailabilityFinderContainer
                cityCoordinates={cityCoordinates}
                appIsLoggedIn={appIsLoggedIn}
                userGroups={userGroups}
                searchGroup={searchGroup}
                // neighbourhoods={neighbourhoods}
                // onMapZoomChange={onMapZoomChange}
                // onMapDrag={resetNeighbourhoods}
                setMapGlobal={setMapGlobal}
              />
            </>}
          </div>
        </Styled>
      </McbComponentPastry>
    );
  }
}

export default withJssMap(McbFinder);
