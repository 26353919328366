import React from "react";
import { observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";

export interface MapSelectionResetButtonProps {
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  onClick: (event: any) => void;
}

export const MapSelectionResetButton: React.FC<MapSelectionResetButtonProps> =
  observer(({ disabled, size, onClick }) => {
    return (
      <Button
        variant="contained"
        size={size || "medium"}
        disabled={disabled}
        onClick={onClick}
        color="secondary"
        className="mapSelectionResetButton"
      >
        <Typography>
          Reset map selection
        </Typography>
      </Button>
    );
  });
