import { observer } from "mobx-react";
import { Dialog } from "@material-ui/core";
import styles from "./styles.css";
import React from "react";
import { Styled } from "direflow-component";
import { ui } from "../../client/mcb-bridge/ui";

export interface AlertBaseProps extends Partial<React.ComponentProps<typeof Dialog>> {
  container?: HTMLDivElement;
  isOpen?: boolean;
  onClose?: (event: any) => void;
  extraStyles?: string;
  showBackdrop?: boolean;
}

export const AlertBase: React.FC<AlertBaseProps> = 
  observer(({ disableBackdropClick, disableEscapeKeyDown, showBackdrop, isOpen, onClose, extraStyles, children }) => {
  const onCloseHandler = (event: any, reason: "backdropClick" | "escapeKeyDown") => {
    if (disableEscapeKeyDown && reason === "escapeKeyDown") return;
    if (disableBackdropClick && reason === "backdropClick") return;
    return onClose(event);
  };
  return <Dialog
    style={{ zIndex: 114514 }}
    fullScreen={ui.isMobile}
    maxWidth="xl"
    disableScrollLock
    open={isOpen}
    onClose={onCloseHandler}
  >
    <>
      {typeof showBackdrop === "boolean" && !showBackdrop && (
        <style>{`.MuiBackdrop-root { opacity: 0 !important }`}</style>
      )}
      <Styled styles={styles + extraStyles}>
        <div className="flex column justify-content-center align-items-center noEvents alertModalBase">
          <div className="alertModalContent">
            {children}
          </div>
        </div>
      </Styled>
    </>
  </Dialog>;
});