// For future configurable flags.
import { observable } from "mobx";
import { daysToMilli } from "../utils/helpers";

const flags = observable({
  globalName: "mycarebase-finder",
  storageCleanupDate: 1652310667619,
  xBaseSwitch: "",
  renewDisable: true,
  deepRenew: true,
  deepRenewExpiresIn: daysToMilli(30),
  loadHiresAvatar: false,
  mcbFinderUseAdvancedFilter: false
});

export default flags;
