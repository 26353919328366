import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { isEmpty } from "../../../utils/helpers";
import { ClearSelectedBlocksButton } from "../ClearSelectedBlocksButton";
import { SetMultipleBlocksButton } from "../SetMultipleBlocksButton";
import { CancelEditDefaultNeighbourhoodSetButton } from "../../DefaultNeighbourhoodSetToolbar/CancelEditDefaultNeighbourhoodSetButton";
import { EditDefaultNeighbourhoodSetButton } from "../../DefaultNeighbourhoodSetToolbar/EditDefaultNeighbourhoodSetButton";
import React from "react";
import styles from "./styles.css";
import { WeeklyScheduleContainerProps } from "../../../lib/types/propTypes";
import { Styled } from "direflow-component";

export interface WeeklyScheduleDesktopToolbarProps extends Partial<WeeklyScheduleContainerProps> {}

export const WeeklyScheduleDesktopToolbar: React.FC<WeeklyScheduleDesktopToolbarProps> = observer(
  ({
     selectedBlocks,
     isEditingDefaultNeighbourhoodSet,
     onDeselectAllBlocks,
     onOpenMultiSetPopUp,
     onEditDefaultNeighbourhoodSet,
     onSaveDefaultNeighbourhoodSet
   }) => (
    <Styled styles={styles}>
      <Grid
        container
        spacing={2}
        className="justify-content-between weeklyScheduleDesktopContainerButtons"
      >
        <Grid item className="weeklyScheduleDesktopContainerButtonGroup">
          <Grid item>
            {!isEmpty(selectedBlocks) && (
              <ClearSelectedBlocksButton
                onClick={onDeselectAllBlocks}
              />
            )}
          </Grid>
          <Grid item>
            {selectedBlocks.length >= 2 && (
              <SetMultipleBlocksButton
                onClick={onOpenMultiSetPopUp}
              />
            )}
          </Grid>
        </Grid>
        <Grid item className="weeklyScheduleDesktopContainerButtonGroup">
          <Grid item>
            {isEditingDefaultNeighbourhoodSet && (
              <CancelEditDefaultNeighbourhoodSetButton
                onClick={onEditDefaultNeighbourhoodSet}
              />
            )}
          </Grid>
          <Grid item>
            <EditDefaultNeighbourhoodSetButton
              isEditing={isEditingDefaultNeighbourhoodSet}
              onClick={isEditingDefaultNeighbourhoodSet
                ? onSaveDefaultNeighbourhoodSet
                : onEditDefaultNeighbourhoodSet
              }
            />
          </Grid>
        </Grid>
      </Grid>
    </Styled>
  ));