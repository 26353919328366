// This version of the storage driver is specifically optimized for single entity JSON.
// Please do not use in exchange with the standalone app version.
import { autorun, observable, when } from "mobx";
import { randomString, safeParseJSON } from "../utils/helpers";
import flags from "../config/flags";

export interface PersistentStore<T> {
  name: string;
  disposer: () => void;
  dispose: () => void;
  clearStore: () => void;
  data: T;
}

class _Storage implements PersistentStore<any> {
  disposer;
  name: string;
  @observable data = {} as any;
  @observable ready: boolean = false;
  constructor() {
    this.name = flags.globalName;
    this.restoreData()
    .then(this.checkSweepOldData)
    .then(this.setDeviceId)
    .then(this.registerStorageListener)
    .catch(console.warn)
    .finally(() => this.ready = true);
    return this;
  }
  getDeviceId = () => this.data.id;
  private setDeviceId = () => !this.data.deviceId && (this.data.id = randomString());
  getDriverData = async () => safeParseJSON(localStorage.getItem(this.name));
  private registerStorageListener = () =>
    this.disposer = autorun(() =>
      localStorage.setItem(this.name, JSON.stringify(this.data))
    );
  private restoreData = async () =>
    this.getDriverData()
    .then(data => {
      if (data) Object.assign(this.data, data);
      return Promise.resolve();
    });
  private checkSweepOldData = async () => {
    const storageCleanupDate = flags.storageCleanupDate && new Date(flags.storageCleanupDate).getTime();
    const lastCleanupDate = Number(this.data.timestamp);
    if (!storageCleanupDate) return;
    if (!lastCleanupDate || lastCleanupDate < storageCleanupDate) {
      await this.clearStore();
      return this.data.timestamp = new Date().getTime();
    }
  };
  isReady = async () => when(() => this.ready);
  dispose = () => this.disposer && this.disposer();
  clearStore = () => {
    this.data = {};
    return localStorage.removeItem(this.name);
  };
}
const _storage = new _Storage();
// if (window && env as string !== "prod") (window as any)._storage = _storage;

export class Store<T> implements PersistentStore<T> {
  disposer;
  name: PersistentStore<T>["name"];
  @observable data: PersistentStore<T>["data"] = {} as T;
  @observable ready: boolean = false;

  constructor(name) {
    this.name = name;
    _storage.isReady()
    .then(this.restoreData)
    .then(this.registerStorageListener)
    .finally(() => this.ready = true);
    return this;
  }

  private registerStorageListener = () =>
    this.disposer = autorun(() =>
      _storage.data[this.name] = this.data
    );

  private restoreData = async () => {
    if (!_storage.data[this.name]) return _storage.data[this.name] = this.data;
    return this.data = _storage.data[this.name];
  };

  initProperty = (propertyName: keyof T, data: T[keyof T]) => {
    if (!this.data.hasOwnProperty(propertyName)) this.data[propertyName] = data;
  };

  isReady = async () => when(() => this.ready);

  dispose = () => this.disposer && this.disposer();

  clearStore = () => this.data = {} as T;
}