import { mcbBridge } from "./index";
import { Controller } from "../../lib/controller";
import { Group, Member, OAuth2Data, PersonalProfile, Profile, User } from "../../lib/types/dataTypes";

export interface McbSessionStore {
  webAppData: any;
  careCircleScratchpadId: Group["id"];
  householdScratchpadId: Group["id"];
}

export interface McbSessionController extends Controller<McbSessionStore> {
  iframe: HTMLIFrameElement;
  sourceUrl: string;

  webAppUrl: string;
  webAppOrigin: string;
  parentOrigin: string;

  runningAuthChangeSequence: boolean;

  deviceId: string;

  readonly isFramed: boolean;
  readonly webAppData: McbSessionStore["webAppData"];
  readonly webAppOAuth: OAuth2Data;
  readonly appCredentialReady: boolean;
  readonly appUser: User;
  readonly appDefaultMember: Member;
  readonly appDefaultGroup: Group;
  readonly appDefaultProfile: Profile<PersonalProfile>;
  readonly appIsLoggedIn: boolean;
  readonly appIsVisitor: boolean;
  readonly isInterimUser: boolean;
  readonly isValidVisitor: boolean;

  readonly careCircleScratchpadId: number;
  readonly householdScratchpadId: number;
  readonly careCircleScratchpad: Group;
  readonly householdScratchpad: Group;

  sendFrameMessage: (data: any) => Promise<void>;

  postParentMessage: (data: any, wildcard?: boolean) => Promise<void>;

  wpAlreadyLoggedIn: () => Promise<boolean>;

  sessionLogout: () => Promise<void>;
}

export let mcbSessionCtrl: McbSessionController = {} as McbSessionController;
export const initMcbSessionCtrl = () => mcbBridge.isInitialized().then(() => mcbSessionCtrl = mcbBridge.getMcbSessionCtrl());