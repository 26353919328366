import { Profile } from "../lib/types/dataTypes";
import {
  AvailabilityBlockId,
  CAFCaregiverProfileData,
  DayOfWeek,
  IndexOfNeighbourhoodSet,
  NeighbourhoodId,
  NeighbourhoodSet
} from "../lib/types/caf/cafProfileTypes";
import {
  defaultNeighbourhoodSetData,
  maximumAllowedNeighbourhoodSets,
  neighbourhoodSetPallet,
  showWeekOfDay,
  timeOfDayDescription,
  timeOfDayName
} from "../config/constants";
import { arrayShuffle, isEmpty } from "./helpers";
import { toJS } from "mobx";

export const getKeyStrId = (dow: DayOfWeek, todIndex: number): AvailabilityBlockId =>
  `${dow}${todIndex.toString().padStart(2, "0")}` as AvailabilityBlockId;

export const getBlockName = (keyStr: AvailabilityBlockId): string => {
  const dow = Number(keyStr[0]);
  const tod = Number(keyStr.slice(1));
  return `${showWeekOfDay[dow]} ${timeOfDayName[tod]} ${timeOfDayDescription[tod]}`;
};

export const getBlockNameSimple = (keyStr: AvailabilityBlockId): string => {
  const dow = Number(keyStr[0]);
  const tod = Number(keyStr.slice(1));
  return `${showWeekOfDay[dow]} ${timeOfDayName[tod]}`;
}

export const cleanupNeighbourhoodSetSequence = (profileData: Profile<CAFCaregiverProfileData>["data"]): void => {
  const { availability, neighbourhoodSets } = profileData || {};
  const cleanup = (neighbourhoodSets: NeighbourhoodSet[]) => (
    neighbourhoodSets.forEach((neighbourhoodSet, i: IndexOfNeighbourhoodSet) => {
      if (i === 0) return; // Skip default;
      const availabilityKeyStrs: AvailabilityBlockId[] = Object.keys(availability) as AvailabilityBlockId[];
      const hasAvailability = availabilityKeyStrs.some(keyStr => availability[keyStr]?.neighbourHoodSet === i);
      if (!hasAvailability) {
        const availabilitiesToUpdate = availabilityKeyStrs.filter(keyStr => availability[keyStr]?.neighbourHoodSet > i);
        availabilitiesToUpdate.forEach(keyStr => availability[keyStr].neighbourHoodSet--);
        neighbourhoodSets.splice(i, 1);
      }
    })
  );
  const neighbourhoodSetsLength = neighbourhoodSets.length;
  for (let i = 0; i < neighbourhoodSetsLength; i++) {
    cleanup(neighbourhoodSets);
  }
};

export const updateSelectedBlockNeighbourhoodSet = (
  profileData: CAFCaregiverProfileData,
  selectedAvailableBlocks: AvailabilityBlockId[],
  neighbourhoodSet: IndexOfNeighbourhoodSet
): AvailabilityBlockId[] => {
  selectedAvailableBlocks.forEach(keyStr => (
    profileData.availability[keyStr].neighbourHoodSet = neighbourhoodSet
  ));
  return selectedAvailableBlocks;
};

export const createNewCustomNeighbourhoodSet = (
  profileData: CAFCaregiverProfileData,
  neighbourhoodIds?: NeighbourhoodId[]
): IndexOfNeighbourhoodSet => {
  if (profileData.neighbourhoodSets.length >= maximumAllowedNeighbourhoodSets) {
    throw new Error("Cannot create more than 29 neighbourhood sets.");
  }
  const neighbourhoodSet: NeighbourhoodSet = {...defaultNeighbourhoodSetData};
  const existingColors = profileData.neighbourhoodSets.map(n => n.color);
  let color = arrayShuffle(neighbourhoodSetPallet)[0];
  while (existingColors.includes(color)) {
    color = arrayShuffle(neighbourhoodSetPallet)[0];
  }
  neighbourhoodSet.color = color;
  neighbourhoodSet.neighbourhoodIds = neighbourhoodIds || [];
  profileData.neighbourhoodSets.push(neighbourhoodSet);
  return profileData.neighbourhoodSets.indexOf(neighbourhoodSet);
};

export const updateCustomSetNeighbourhoodIds = (
  profileData: CAFCaregiverProfileData,
  index: IndexOfNeighbourhoodSet,
  newNeighbourhoodIds: NeighbourhoodId[]
): NeighbourhoodSet => {
  const neighbourhoodSet = profileData.neighbourhoodSets[index];
  if (isEmpty(neighbourhoodSet)) return;
  neighbourhoodSet.neighbourhoodIds = newNeighbourhoodIds;
  return neighbourhoodSet;
};

export const updateNeighbourhoodIds = (
  neighbourhoodSet: Partial<NeighbourhoodSet>,
  neighbourhoodId: NeighbourhoodId
): NeighbourhoodId[] => {
  let newNeighbourhoodIds = toJS([...neighbourhoodSet.neighbourhoodIds]);
  newNeighbourhoodIds.includes(neighbourhoodId)
    ? newNeighbourhoodIds = newNeighbourhoodIds.filter(id => id !== neighbourhoodId)
    : newNeighbourhoodIds.push(neighbourhoodId)
  return newNeighbourhoodIds.filter(Boolean);
};