import { Controller } from "../../lib/controller";
import { IObservableArray } from "mobx";
import { Topic, TopicDTO, TopicPrimitive, TopicType } from "../../lib/types/topicTypes";
import { Group, Member } from "../../lib/types/dataTypes";
import { AxiosResponse } from "axios";
import { mcbBridge } from "./index";

export interface TopicControllerStore {
  topics: IObservableArray<Topic>;
}

export interface TopicController extends Controller<TopicControllerStore> {
  getTopicById: (topicId: Topic["id"], memberId?: Member["id"]) => Promise<Topic>;

  getTopicsByTypeId: (
    typeId: Topic["typeId"],
    isT?: boolean,
    isPT?: boolean,
    groupId?: Group["id"]
  ) => Promise<Topic[]>;

  getTopicsByGroupId: (groupId: Group["id"], topicOnly?: boolean) => Promise<Topic[]>;

  getGroupTopicsByTypeId: (
    groupId: Group["id"],
    typeId: Topic["typeId"],
    isT?: boolean, isPT?: boolean
  ) => Promise<Topic[]>;

  getSubTopicsByParentId: (parentId: Topic["parentId"], topicOnly?: boolean) => Promise<Topic[]>;

  getTopicsByTemplateTopicId: (templateTopicId: Topic["id"], topicOnly?: boolean) => Promise<Topic[]>;

  getTopicTypeById: (topicTypeId: Topic["typeId"]) => Promise<TopicType>;

  patchTopic: (id: Topic["id"], update: Partial<TopicPrimitive | TopicDTO>) => Promise<AxiosResponse>;

  deleteTopicById:  (id: Topic["id"], dontRemove?: boolean) => Promise<any>;
}

export let topicCtrl: TopicController = {} as TopicController;
export const initTopicCtrl = () => mcbBridge.isInitialized().then(() => topicCtrl = mcbBridge.getTopicCtrl());