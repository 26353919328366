import React, { useEffect, useState } from "react";
import { Styled } from "direflow-component";
import { observer } from "mobx-react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";
import styles from "./styles.css";
import { CancelButton } from "../CancelButton";
import { ApplyButton } from "../ApplyButton";
import { WeeklyScheduleGridContainerProps } from "../../../WeeklyScheduleGridContainer";
import { isEmpty, isNonZeroFalse } from "../../../../../utils/helpers";
import { NeighbourhoodSetIndicator } from "../../../../NeighbourhoodSetIndicator";
import { IndexOfNeighbourhoodSet } from "../../../../../lib/types/caf/cafProfileTypes";
import { autorun, toJS } from "mobx";
import { defaultNeighbourhoodSetIndex } from "../../../../../config/constants";
import { Typography } from "@material-ui/core";

export interface SetAvailableMultipleBlocksToggleProps extends Partial<WeeklyScheduleGridContainerProps> {
  handleCloseMultiSetPopUp: () => void;
}

type AvailabilityButtonAlignments = "none" | "mix" | "all";

export const SetAvailableMultipleBlocksToggle: React.FC<SetAvailableMultipleBlocksToggleProps> =
  observer(
    ({
       selectedBlocks,
       neighbourhoodSets,
       getBlockIsAvailable,
       getBlockNeighbourhoodSetIndex,
       onSetMultipleBlocksAvailability,
       onSetMultipleBlocksNeighbourhoodSet,
       onOverrideMapNeighbourhoodIndex,
       handleCloseMultiSetPopUp,
     }) => {
      const yesCount = (selectedBlocks || []).filter(keyStr => getBlockIsAvailable(keyStr)).length;
      const initialToggleState = yesCount === selectedBlocks.length ? "all" : isEmpty(yesCount) ? "none" : "mix";
      const [alignment, setAlignment] = useState<AvailabilityButtonAlignments>(initialToggleState);

      const currentNeighbourhoodSetIndices = Array.from(new Set(selectedBlocks.map(keyStr => getBlockNeighbourhoodSetIndex(keyStr))));
      const currentNeighbourhoodSetIndex = currentNeighbourhoodSetIndices.length === 1 && currentNeighbourhoodSetIndices[0];
      const [neighbourhoodSetIndex, setNeighbourhoodSetIndex] = useState<IndexOfNeighbourhoodSet>(currentNeighbourhoodSetIndex);
      useEffect(() => autorun(() => setNeighbourhoodSetIndex(currentNeighbourhoodSetIndex)), [currentNeighbourhoodSetIndex]);

      const [applyDisabled, setApplyDisabled] = useState(true);
      const [applyClicked, setApplyClicked] = useState(false);
      useEffect(() => {
        const neighbourhoodSetChanged = currentNeighbourhoodSetIndex !== neighbourhoodSetIndex;
        const alignmentChanged = initialToggleState !== alignment;
        const changed = neighbourhoodSetChanged || alignmentChanged;
        setApplyDisabled(!changed);
      }, [alignment, neighbourhoodSetIndex, currentNeighbourhoodSetIndex, initialToggleState]);

      const handleAlignment = (
        event: React.MouseEvent<HTMLElement>,
        newAlignment: AvailabilityButtonAlignments
      ) => {
        if (newAlignment === "none") {
          onOverrideMapNeighbourhoodIndex("off");
        }
        if (newAlignment === "all") {
          const availableNeighbourhoodIndices = currentNeighbourhoodSetIndices.filter(Boolean);
          const isSettingNewToDefaultSet = (
            availableNeighbourhoodIndices.length === 1
            && availableNeighbourhoodIndices[0] === defaultNeighbourhoodSetIndex
          );
          if (isEmpty(availableNeighbourhoodIndices) || isSettingNewToDefaultSet) {
            setTimeout(() => handleNeighbourhoodSetSelect(null, neighbourhoodSetIndex || defaultNeighbourhoodSetIndex));
          }
          if (neighbourhoodSetIndex === currentNeighbourhoodSetIndex) {
            onOverrideMapNeighbourhoodIndex(null);
          }
        }
        setAlignment(newAlignment);
      };

      const handleNeighbourhoodSetSelect = (
        event: React.MouseEvent<HTMLElement>,
        index: IndexOfNeighbourhoodSet,
      ) => {
        setNeighbourhoodSetIndex(index);
        onOverrideMapNeighbourhoodIndex(index === currentNeighbourhoodSetIndex ? null : index);
      };

      const handleApply = async () => {
        if (alignment === "mix") return;
        const isYes = alignment === "all";
        onSetMultipleBlocksAvailability(isYes);
        const neighbourhoodSetChanged = neighbourhoodSetIndex !== currentNeighbourhoodSetIndex;
        if (neighbourhoodSetChanged) onSetMultipleBlocksNeighbourhoodSet(selectedBlocks, neighbourhoodSetIndex);
        onOverrideMapNeighbourhoodIndex(null);
        setApplyClicked(true);
      };

      return (
        <Styled styles={styles}>
          <div className="flex column justify-content-evenly align-items-center setAvailableMultipleBlocksToggle">
            <div className="flex justify-content-center align-items-end toggles">
              <ToggleButtonGroup
                size="small"
                value={alignment}
                exclusive
                onChange={handleAlignment}
                className="flex toggleButtonGroup"
              >
                <ToggleButton
                  value="none"
                  disabled={alignment === "none"}
                  className={`toggleButtonItem ${
                    alignment === "none"
                      ? "toggleButtonSelectedItem"
                      : "toggleButtonNonSelectedItem"
                  }`}
                >
                  ALL NO
                </ToggleButton>
                {/*<ToggleButton*/}
                {/*  value="mix"*/}
                {/*  disabled={*/}
                {/*    alignment === "none" ||*/}
                {/*    alignment === "all" ||*/}
                {/*    alignment === "mix"*/}
                {/*  }*/}
                {/*  className={*/}
                {/*    alignment === "mix"*/}
                {/*      ? "toggleButtonSelectedItem"*/}
                {/*      : "toggleButtonDisabledItem"*/}
                {/*  }*/}
                {/*>*/}
                {/*  <Grid container direction="column">*/}
                {/*    <Grid item>YES: {yesCount}</Grid>*/}
                {/*    <Grid item>NO: {selectedBlocks.length - yesCount}</Grid>*/}
                {/*  </Grid>*/}
                {/*</ToggleButton>*/}
                <ToggleButton
                  value="all"
                  disabled={alignment === "all"}
                  className={`toggleButtonItem ${
                    alignment === "all"
                      ? "toggleButtonSelectedItem"
                      : "toggleButtonNonSelectedItem"
                  }`}
                >
                  ALL YES
                </ToggleButton>
              </ToggleButtonGroup>
            </div>
            <div className="flex align-items-end neighbourhoodSelectionHint">
              <Typography className="scrollHint">
                To change the neighbourhood set, select another colour.
              </Typography>
            </div>
            <div className="flex column align-items-center justify-content-center indicators">
              <NeighbourhoodSetIndicator
                disabled={alignment !== "all"}
                defaultNeighbourhoodSet={neighbourhoodSets[0]}
                neighbourhoodSets={toJS(neighbourhoodSets).slice(1)}
                selectedNeighbourhoodSetIndex={alignment === "all" && neighbourhoodSetIndex}
                onNeighbourhoodSetSelect={handleNeighbourhoodSetSelect}
              />
              <div className="flex align-items-end neighbourhoodSelectionHint">
                {alignment === "all" && isNonZeroFalse(neighbourhoodSetIndex) && (
                  <Typography className="scrollHint">
                    It is recommended that you choose a neighbourhood set colour before you APPLY.
                  </Typography>
                )}
              </div>
            </div>
            <div className="flex justify-content-between buttonGroup">
              <CancelButton
                onClick={handleCloseMultiSetPopUp}
                showClose={applyClicked && applyDisabled}
              />
              <ApplyButton
                onClick={handleApply}
                disabled={alignment === "mix" || applyDisabled}
              />
            </div>
          </div>
        </Styled>
      );
    }
  );
