import React from "react";
import { observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";

export interface SetMultipleBlocksButtonProps {
  disabled?: boolean;
  onClick: (event: any) => void;
}

export const SetMultipleBlocksButton: React.FC<SetMultipleBlocksButtonProps> =
  observer(({ disabled, onClick }) => {
    return (
      <Button
        variant="contained"
        size="medium"
        disabled={disabled}
        onClick={onClick}
        color="secondary"
        className="setMultipleButton"
      >
        <Typography>
          EDIT SELECTED TIME BLOCKS
        </Typography>
      </Button>
    );
  });
