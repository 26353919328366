import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import { ViewResultListButton } from "./ViewResultListButton";
import { MapSelectionResetButton } from "./MapSelectionResetButton";
import { GridSelectionResetButton } from "./GridSelectionResetButton";
import { ShowSavedCaregiversButton } from "./ShowSavedCaregiversButton";

export interface AvailabilityFinderToolbarProps {
  hasMapSelection?: boolean;
  hasGridSelection?: boolean;
  searchResultReady?: boolean;
  savedCaregiversCount?: number;
  searchResultCount?: number;
  onResetMapSelection?: (event: any) => void;
  onResetGridSelection?: (event: any) => void;
  onOpenResultList: (event: any) => void;
  onOpenSavedList: (event: any) => void;
}

export const AvailabilityFinderToolbar: React.FC<AvailabilityFinderToolbarProps> = observer(
  ({
     hasMapSelection,
     hasGridSelection,
     savedCaregiversCount,
     searchResultReady,
     searchResultCount,
     onResetMapSelection,
     onResetGridSelection,
     onOpenResultList,
     onOpenSavedList
   }) => (
    <Styled styles={styles}>
      <div className="flex availabilityFinderToolbar">
        <div>
          {hasMapSelection && (
            <MapSelectionResetButton
              disabled={!hasMapSelection}
              onClick={onResetMapSelection}
            />
          )}
          {hasGridSelection && (
            <GridSelectionResetButton
              disabled={!hasGridSelection}
              onClick={onResetGridSelection}
            />
          )}
        </div>
        <div className="flex column rightButtons">
          <ShowSavedCaregiversButton
            onClick={onOpenSavedList}
            count={savedCaregiversCount}
            disabled={savedCaregiversCount === 0}
          />
          {searchResultReady && <>
            <ViewResultListButton
              count={searchResultCount}
              disabled={searchResultCount === 0}
              onClick={onOpenResultList}
            />
          </>}
        </div>
      </div>
    </Styled>
  ));