import React from "react";
import { Observer, observer } from "mobx-react";
import { Button, CircularProgress, Grid, Typography } from "@material-ui/core";
import { AvailabilityFinderController } from "./controller";
import IGoogleMaps from "../IGoogleMaps";
import { ui } from "../../client/mcb-bridge/ui";
import { computed, IObservableArray, observable, reaction } from "mobx";
import styles from "./styles.css";
import { Styled } from "direflow-component";
import { WeeklyScheduleGridContainer } from "../WeeklyScheduleGrid/WeeklyScheduleGridContainer";
import { AvailabilityFinderToolbar } from "../AvailabilityFinderToolbar";
import { getDisplayNameEng, isEmpty, preventDefaultStopProp } from "../../utils/helpers";
import { env } from "../../config/env";
import { Coordinate, Neighbourhood } from "../../lib/types/caf/geoDataTypes";
import { responsive } from "../../config/styles/responsive";
import { stateCtrl } from "../../client/mcb-bridge/state";
import { defaultCity, showWeekOfDay } from "../../config/constants";
import NeighbourhoodCenterSelector from "../NeighbourhoodCenterSelector";
import { Group } from "../../lib/types/dataTypes";
import { computedFn } from "mobx-utils";

type Map = google.maps.Map;

export interface AvailabilityFinderContainerProps {
  cityCoordinates: Coordinate;
  appIsLoggedIn: boolean;
  userGroups: Group[];
  searchGroup: Group;
  // neighbourhoods: Neighbourhood[];
  // onMapZoomChange?: (zoom: number) => any;
  onMapDrag?: () => Promise<Neighbourhood[]>;
  setMapGlobal?: (map: Map) => void;
}

@observer
class AvailabilityFinderContainer extends React.Component<AvailabilityFinderContainerProps> {
  readonly mapDefaultZoom = 13;

  controller: AvailabilityFinderController;

  @observable profileSaveLoading: IObservableArray<number> = observable([]);

  @computed get searchLoading(): boolean {
    return this.controller.searchLoading;
  };

  constructor(props) {
    super(props);
    this.controller = new AvailabilityFinderController(props);
    this.controller.initialize().catch(this.showError);
    this.controller.disposers.push(this.setCriteriaUseViewMaxPointForMobileWidth());
    stateCtrl.addMarketResultsPopupSaveHandler("onSaveClick", this.handleProfileSave);
    stateCtrl.getMarketResultsPopupProfileSaveLoading(this.isProfileSaveLoading);
    stateCtrl.setMarketResultsPopupChatHandler(this.handleProfileChat);
  }

  componentDidUpdate() {
    this.controller?.updateProps(this.props);
  };

  componentWillUnmount() {
    this.controller.dispose();
  };

  showError = err => ui.showError({ err, actionName: "Availability Finder" });

  setCriteriaUseViewMaxPointForMobileWidth = () => {
    const updateIsMobile = () => stateCtrl && stateCtrl.setMarketCriteriaFormUseMobile(
      responsive.deviceDimension.isViewMaxCAFCriteria
    );
    updateIsMobile();
    return reaction(() => responsive.deviceDimension.isViewMaxCAFCriteria, updateIsMobile);
  };

  renderCenterSelector = () => <Observer>{() => (
    <Grid
      container
      item
      justifyContent="center"
      alignItems="center"
      className="centerSelector"
    >
      {/*<OSMPlaceSelector*/}
      {/*  value={this.controller.enteredLocation as OSMPlaceData}*/}
      {/*  placeholder={defaultCity}*/}
      {/*  helperText="Address, Neighbourhood, City, Postal code..."*/}
      {/*  onPositionChange={this.controller.handleMapCenterChange}*/}
      {/*/>*/}
      <NeighbourhoodCenterSelector
        biasCenter
        helperText="Address, Neighbourhood, City, Postal code..."
        placeholder={defaultCity}
        value={this.controller.enteredLocation}
        onPositionChange={this.controller.handleMapCenterChange}
      />
    </Grid>
  )}</Observer>;

  showCommuteTimeDebug = () => {
    const { searchResultGroups } = this.controller;
    if (isEmpty(searchResultGroups)) return ui.showAlert({
      title: "Show calculated commute time",
      message: "No matching results"
    });

    const resultItems = searchResultGroups.map(group => `Name: ${getDisplayNameEng(group.profile)}
Availabilities: 
  ${(group.availabilityWithCommuteTime || []).map(
    a => `  ${showWeekOfDay[a.dayOfWeek]}, Start: ${a.start}, End: ${a.end}, Location: ${a.location}, Max: ${a.maxCommuteMinutes} min., Calc: ${
      a.calculatedCommuteTime ? a.calculatedCommuteTime.toFixed(2) : "-"
    } min.`
    ).join("\n  ")}`).join("\n\n");

    return ui.showAlert({
      title: "Show calculated commute time",
      message: resultItems
    });
  };

  handleSaveSearchForm = (event: any) => {
    preventDefaultStopProp(event);
    return this.controller.handleSaveSearchForm()
    .catch(this.showError);
  };

  isProfileSaveLoading = computedFn((profileId: number) => (
    this.profileSaveLoading.includes(profileId)
  ));

  handleProfileSave = (event: any, profileId: number) => {
    preventDefaultStopProp(event)
    this.profileSaveLoading.push(profileId);
    return (this.controller.savedProfileIds.includes(profileId)
      ? this.controller.onProfileUnSave
      : this.controller.onProfileSave)(profileId)
    .catch(err => {
      if (this.controller.savedProfileIds.includes(profileId)) {
        if (err?.response?.status && err.response.status === 304) {
          return ui.showAlert({
            title: "Cannot remove saved Caregiver",
            message: "You have existing chat messages with this Caregiver."
          });
        }
      }
      return this.showError(err);
    })
    .finally(() => this.profileSaveLoading.remove(profileId));
  };

  handleProfileChat = (profileId: number) => {
    return this.controller.onProfileChat(profileId)
    .catch(this.showError);
  };

  render() {
    const {
      mapViewportCenter,
      // neighbourhoods,
      selectedBlocks,
      // selectedNeighbourhoodIds,
      showViewAllButton,
      searchResultColor,
      searchResultCount,
      // searchResultNeighbourhoodIds,
      // mapPolygonMarkers,
      getBlockIsSelected,
      // getBlockIsInSearchResult,
      getBlockColor,
      getBlockCoverText,
      getBlockShowDeselectButton,
      handleBlockCoverTextClick,
      setMapTypeId,
      setMapGlobal,
      handleMapZoom,
      setMapCenter,
      handleBlockSelect,
      handleDeselectAllBlocks,
      // handleMapNeighbourhoodSelect,
      // handleDeselectAllNeighbourhoods,
      handleOpenResultList,
      handleOpenSavedList,
      searchFormDirty,
      criteriaLoading,
      candidateTopics,
      searchResultDebug,
      toggleDebug
    } = this.controller;

    const market: {
      criteria: any,
      results: any
    } = {
      criteria: `mcb-market-criteria-form`,
      results: `mcb-market-results-popup`
    };

    return (
      <Styled styles={styles}>
        <Grid
          container
          direction="row"
          justifyContent="flex-start"
          alignItems="center"
          className="availabilityFinderContainer"
        >
          {this.renderCenterSelector()}
          <Grid item className="relative availabilityMap">
            <IGoogleMaps
              center={mapViewportCenter}
              // showCenterPin
              showLockedCenterPin
              hideTitle
              hideSelector
              // helperMessage={ui.isMobile ? "Tap on the map to enter a location and select a neighbourhood." : null}
              helperMessage={ui.isMobile ? "Tap to expand the map and select a point." : null}
              zoom={this.mapDefaultZoom}
              polygonColor={searchResultColor}
              // customPolygonMarkers={mapPolygonMarkers}
              // neighbourhoods={neighbourhoods}
              // selectedNeighbourhoodIds={searchResultNeighbourhoodIds}
              customPolygonMarkers={[]}
              neighbourhoods={[]}
              selectedNeighbourhoodIds={[]}
              setMapGlobal={setMapGlobal}
              // onNeighbourhoodClick={handleMapNeighbourhoodSelect}
              onMapTypeIdChange={setMapTypeId}
              onMapZoomChange={handleMapZoom}
              onMapCenterChange={setMapCenter}
              MobileMapAdditionalComponentsTop={(
                <div style={{ marginTop: "20px" }}>
                  {this.renderCenterSelector()}
                </div>
              )}
            />
            {this.searchLoading && (
              <div className="flex justify-content-center align-items-center searchLoading absolute">
                <CircularProgress size={20} />&nbsp;&nbsp;
                <Typography color="primary" className="textBold font-s">
                  Loading Caregiver availability
                </Typography>
              </div>
            )}
          </Grid>

          <AvailabilityFinderToolbar
            // hasMapSelection={!isEmpty(selectedNeighbourhoodIds)}
            hasMapSelection={false}
            hasGridSelection={!isEmpty(selectedBlocks)}
            savedCaregiversCount={candidateTopics.length}
            searchResultReady={showViewAllButton}
            searchResultCount={searchResultCount}
            // onResetMapSelection={handleDeselectAllNeighbourhoods}
            onResetGridSelection={handleDeselectAllBlocks}
            onOpenResultList={handleOpenResultList}
            onOpenSavedList={handleOpenSavedList}
          />

          <div className="flex justify-content-center availabilityCriteriaGridWrapper">
            <div
              className="flex column criteriaWrapper"
              onClick={this.controller.setSearchFormClicked}
            >
              <Typography color="secondary" className="textBold font-m refineYourSearch" gutterBottom>
                Refine your search
              </Typography>
              {this.props.appIsLoggedIn && (
                <Button
                  className="saveButton"
                  variant="text"
                  size="small"
                  color="primary"
                  disabled={!searchFormDirty || criteriaLoading}
                  onClick={this.handleSaveSearchForm}
                >
                  Save changes to profile
                </Button>
              )}
              {!isEmpty(this.controller.searchForm) && (
                <market.criteria criteriaHidden />
              )}
            </div>
            <Grid
              container
              direction="row"
              item
              justifyContent="center"
              alignItems="flex-start"
              className={`availabilityGrid ${searchResultDebug ? "debug" : ""}`}
            >
              <WeeklyScheduleGridContainer
                selectedBlocks={[]}
                neighbourhoodSets={[]}
                getBlockIsSelected={() => false}
                getBlockIsAvailable={getBlockIsSelected}
                getBlockColor={getBlockColor}
                getBlockNeighbourhoodSetIndex={() => 0}
                getBlockCoverText={getBlockCoverText}
                getBlockShowDeselectButton={getBlockShowDeselectButton}
                hideToolbar
                onSelectBlock={handleBlockSelect}
                onDeselectBlock={handleBlockSelect}
                onDeselectAllBlocks={() => {}}
                onSetAvailability={() => {}}
                onSetMultipleBlocksAvailability={() => {}}
                onSelectNeighbourhoodSet={() => {}}
                onSetMultipleBlocksNeighbourhoodSet={() => {}}
                onEditDefaultNeighbourhoodSet={() => {}}
                onSaveDefaultNeighbourhoodSet={() => {}}
                onOverrideMapNeighbourhoodIndex={() => {}}
                onBlockCoverTextClick={handleBlockCoverTextClick}
              />
            </Grid>
          </div>

          <market.results />
          {!env.match(/prod/ig) && (
            <Grid
              container
              direction="row"
              item
              alignItems="flex-end"
              justifyContent="flex-end"
            >
              {searchResultDebug && (
                <Button size="small" className="textUnderline font-xs" onClick={this.showCommuteTimeDebug}>
                  Show calculated commute time
                </Button>
              )}
              <Button size="small" className="textUnderline font-xs" onClick={toggleDebug}>
                Debug
              </Button>
            </Grid>
          )}
        </Grid>
      </Styled>
    );
  }
}

export default AvailabilityFinderContainer;