import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component";

import styles from "./styles.css";
import { NeighbourhoodSetIndicatorColor } from "./NeighbourhoodSetIndicatorColor";
import { IndexOfNeighbourhoodSet, NeighbourhoodSet } from "../../lib/types/caf/cafProfileTypes";

export interface NeighbourhoodSetSelectorProps {
  disabled?: boolean;
  defaultNeighbourhoodSet: NeighbourhoodSet;
  neighbourhoodSets: NeighbourhoodSet[];
  selectedNeighbourhoodSetIndex: IndexOfNeighbourhoodSet;
  onNeighbourhoodSetSelect: (event: React.MouseEvent<HTMLElement>, index: IndexOfNeighbourhoodSet) => void;
}

export const NeighbourhoodSetIndicator: React.FC<NeighbourhoodSetSelectorProps> =
  observer(({ disabled, defaultNeighbourhoodSet, neighbourhoodSets, selectedNeighbourhoodSetIndex, onNeighbourhoodSetSelect}) => (
    <Styled styles={styles}>
      <div
        className="flex column align-items-center justify-content-center neighbourhoodSetIndicatorContainer"
        style={{ opacity: disabled ? .5 : 1 }}
      >
        <div className="flex justify-content-center neighbourhoods">
          <div className="defaultNeighbourhood">
            <NeighbourhoodSetIndicatorColor
              text="D"
              isSelected={selectedNeighbourhoodSetIndex === 0}
              color={defaultNeighbourhoodSet.color}
              disabled={disabled}
              onClick={(e) => onNeighbourhoodSetSelect(e, 0)}
            />
          </div>
          {neighbourhoodSets.length <= 30 && neighbourhoodSets.map((neighbourhoodSet, i) => {
            const index = i + 1;
            return <NeighbourhoodSetIndicatorColor
              key={index}
              isSelected={index === selectedNeighbourhoodSetIndex}
              color={neighbourhoodSet.color}
              disabled={disabled}
              onClick={(e) => onNeighbourhoodSetSelect(e, index)}
            />;
          })}
        </div>
      </div>
    </Styled>
  ));
