import { observable } from "mobx";

class ResponsiveStyles {
  mobileWidth: number = 768;
  mobileWidthIntermediate: number = 960;
  viewMaxWidth: number = 1366;
  viewMaxWidthCAFCriteria = 1432;
  viewMaxWidthXW: number = 1600;

  mql = window.matchMedia(`(max-width: ${this.mobileWidth}px)`);
  miMql = window.matchMedia(`(max-width: ${this.mobileWidthIntermediate}px)`);
  viewMaxMql = window.matchMedia(`(max-width: ${this.viewMaxWidth}px)`);
  viewMaxWidthCAFCriteriaMql = window.matchMedia(`(max-width: ${this.viewMaxWidthCAFCriteria}px)`);
  viewMaxXWMql = window.matchMedia(`(max-width: ${this.viewMaxWidthXW}px)`);
  @observable deviceDimension = {
    isMobile: this.mql.matches,
    isMobileIntermediate: this.miMql.matches,
    isViewMax: this.viewMaxMql.matches,
    isViewMaxCAFCriteria: this.viewMaxWidthCAFCriteriaMql.matches,
    isViewMaxXW: this.viewMaxXWMql.matches
  };

  constructor () {
    this.mql.addListener(event => (
      this.deviceDimension.isMobile = event.matches
    ));
    this.miMql.addListener(event => (
      this.deviceDimension.isMobileIntermediate = event.matches
    ));
    this.viewMaxMql.addListener(event => (
      this.deviceDimension.isViewMax = event.matches
    ));
    this.viewMaxWidthCAFCriteriaMql.addListener(event => (
      this.deviceDimension.isViewMaxCAFCriteria = event.matches
    ));
    this.viewMaxXWMql.addListener(event => (
      this.deviceDimension.isViewMaxXW = event.matches
    ));
  }
}

export const responsive = new ResponsiveStyles();