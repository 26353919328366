import React from "react";
import { Observer, observer } from "mobx-react";
import { GoogleMap, Marker, Polygon, Rectangle } from "@react-google-maps/api";
import { action, computed, observable, when } from "mobx";
import { Button, CircularProgress, Typography } from "@material-ui/core";
import mapStyles, { mapPolygonMarkerLabelStyles, mapPolygonStyles } from "../../config/styles/map-styles";
import styles from "./styles.css";
import { Styled } from "direflow-component";
import { MultipleNeighbourhoodSelector } from "../MultipleNeighbourhoodSelector";
import { computedFn } from "mobx-utils";
import { ui } from "../../client/mcb-bridge/ui";
import { Close } from "@material-ui/icons";
import { AlertBase } from "../AlertBase";
import McbComponentPastry from "../McbComponentPastry";
import { placesApi } from "../../client/mcb-bridge/places";
import { mcbBridge } from "../../client/mcb-bridge";
import { NeighbourhoodId } from "../../lib/types/caf/cafProfileTypes";
import { Coordinate, Neighbourhood } from "../../lib/types/caf/geoDataTypes";
import { getNeighbourhoodLocation, getNeighbourhoodPaths, getNeighbourhoodRectangleBounds } from "../../utils/geo-utilities";
import { isEmpty, preventDefaultStopProp, wrapSpaceAndHypenText } from "../../utils/helpers";
import { vancouver } from "../../config/constants";

type Map = google.maps.Map;
type MapOptions = google.maps.MapOptions;
type MapMouseEvent = google.maps.MapMouseEvent;
type MapsPolygon = google.maps.Polygon;
type MapsRectangle = google.maps.Rectangle;
// type MapsCircle = google.maps.Circle;
type MapsCircle = google.maps.Marker;
type PolygonOptions = google.maps.PolygonOptions;
type RectangleOptions = google.maps.RectangleOptions;
type CircleOptions = google.maps.CircleOptions;
type MarkerOptions = google.maps.MarkerOptions;
type MarkerLabel = google.maps.MarkerLabel;
type RenderablePolygon = (PolygonOptions & { id: number });
type RenderableRectangle = (RectangleOptions & { id: number });
type RenderableCircle = (CircleOptions & { id: number });
type RenderablePolygonMarker = (MarkerOptions & { id: number });
type MapTypeId = google.maps.MapTypeId;
type LocalAreaValue = { neighbourhoodId: number; name: string; };
type LatLng = google.maps.LatLng;

const StaticMarker: React.FC<React.HTMLProps<HTMLImageElement>> = ({ style }) => (
  <img alt="" src="https://maps.gstatic.com/mapfiles/api-3/images/spotlight-poi3.png" style={style} />
);

export interface CustomPolygonMarkerOption {
  neighbourhoodId: NeighbourhoodId;
  styles: Partial<MarkerLabel>;
  content: string;
  zoom?: number;
}

export interface IGoogleMapsProps {
  center: Coordinate;
  showCenterPin?: boolean;
  showLockedCenterPin?: boolean;
  zoom: number;
  title?: string;
  helperMessage?: string;
  hideTitle?: boolean;
  hideSelector?: boolean;
  hideCenterField?: boolean;
  polygonColor?: string;
  polygonReadonly?: boolean;
  customPolygonMarkers?: CustomPolygonMarkerOption[];
  neighbourhoods: Neighbourhood[];
  selectedNeighbourhoodIds: number[];
  setMapGlobal?: (map: Map) => void;
  onCenterChange?: (latLng: LatLng) => any;
  onMapTypeIdChange?: (typeId: MapTypeId) => any;
  onMapZoomChange?: (zoom: number) => any;
  onMapCenterChange?: (center: LatLng) => any;
  onNeighbourhoodClick?: (neighbourhoodId: number) => void;
  onSelectedNeighbourhoodsUpdate?: (neighbourhoodIds: number[]) => void;
  MobileMapAdditionalComponentsBottom?: React.Component | React.ReactNode;
  MobileMapAdditionalComponentsTop?: React.Component | React.ReactNode;
}

@observer
class IGoogleMaps extends React.Component<IGoogleMapsProps> {
  map: Map;
  container: HTMLDivElement;

  readonly mapOptions: Partial<MapOptions> = {
    clickableIcons: false,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: true,
    gestureHandling: "greedy",
    styles: mapStyles
  };

  readonly previewMapOptions: Partial<MapOptions> = {
    clickableIcons: false,
    streetViewControl: false,
    fullscreenControl: false,
    scrollwheel: false,
    zoomControl: false,
    zoom: 11,
    mapTypeControl: false,
    disableDoubleClickZoom: true,
    draggable: false,
    panControl: false,
    rotateControl: false,
    styles: mapStyles
  };

  @observable loading: boolean = true;

  @observable mapZoom: number;
  @observable mapTypeId: MapTypeId;
  @observable mapCenter: LatLng;

  @observable polygons: { [key: number]: MapsPolygon } = {};
  @observable rectangles: { [key: number]: MapsRectangle } = {};
  @observable circles: { [key: number]: MapsCircle } = {};

  @observable mobileMapIsOpen: boolean = false;

  constructor(props) {
    super(props);
    this.initialize();
  }

  @computed get selectedNeighbourhoodIds(): number[] {
    return this.props.selectedNeighbourhoodIds || [];
  };
  @computed get neighbourhoods(): Neighbourhood[] {
    return this.props.neighbourhoods || [];
  };

  @computed get markerOptions(): Partial<RenderablePolygonMarker> {
    return {
      clickable: !this.props.polygonReadonly,
      draggable: false,
      icon: {
        path: google.maps.SymbolPath.CIRCLE,
        scale: 0
      }
    };
  };

  /** Labels **/
  @computed get neighbourhoodLabelMarkers(): RenderablePolygonMarker[] {
    return this.neighbourhoods
    .filter(neighbourhood => (
      !isEmpty(neighbourhood.geometry)
      && neighbourhood.municipality === vancouver
    ))
    .map((neighbourhood: Neighbourhood) => ({
      ...this.markerOptions,
      id: neighbourhood.id,
      position: getNeighbourhoodLocation(neighbourhood),
      label: {
        ...mapPolygonMarkerLabelStyles,
        color: this.mapTypeId === "hybrid" ? "#fff" : "#383434",
        fontSize: this.mapZoom < 17 ? `${this.mapZoom * 1.0}px` : `${17 * 1.0}px`,
        text: wrapSpaceAndHypenText(neighbourhood.name),
        className: "textShadow"
      }
    }));
  };
  // @computed get municipalitiesAsNeighbourhoodLabelMarkers(): RenderablePolygonMarker[] {
  //   return this.neighbourhoods
  //   .filter(neighbourhood => neighbourhood.municipality === neighbourhood.name)
  //   .map((neighbourhood: Neighbourhood) => ({
  //     ...this.markerOptions,
  //     id: neighbourhood.id,
  //     position: getNeighbourhoodLocation(neighbourhood),
  //     label: {
  //       ...mapPolygonMarkerLabelStyles,
  //       color: this.mapTypeId === "hybrid" ? "#fff" : "#383434",
  //       fontSize: `${this.mapZoom * 1.3}px`,
  //       text: neighbourhood.name
  //     }
  //   }));
  // };
  @computed get neighbourhoodWithoutGeomLabelMarkers(): RenderablePolygonMarker[] {
    return this.neighbourhoods
    // .filter(neighbourhood => isEmpty(neighbourhood.geometry))
    .filter(neighbourhood => neighbourhood.municipality !== vancouver)
    .map((neighbourhood: Neighbourhood) => ({
      ...this.markerOptions,
      id: neighbourhood.id,
      position: getNeighbourhoodLocation(neighbourhood),
      label: {
        ...mapPolygonMarkerLabelStyles,
        color: this.mapTypeId === "hybrid" ? "#fff" : "#383434",
        fontSize: this.mapZoom === 13 ? `${this.mapZoom * 1.1}px` : this.mapZoom === 14 ? `${this.mapZoom * 1.2}px` : `${15 * 1.2}px`,
        text: wrapSpaceAndHypenText(neighbourhood.name),
        className: "textShadow"
      }
    }));
  };

  /** Markers **/
  @computed get neighbourhoodCircleMarkers(): RenderablePolygonMarker[] {
    return this.neighbourhoods
    // .filter(neighbourhood => isEmpty(neighbourhood.geometry))
    .filter(neighbourhood => neighbourhood.municipality !== vancouver)
    .map((neighbourhood: Neighbourhood) => ({
      ...this.markerOptions,
      id: neighbourhood.id,
      position: getNeighbourhoodLocation(neighbourhood),
      icon: {
        ...this.getPolygonFillProperties(neighbourhood),
        scale: 7 * (this.mapZoom / this.props.zoom) ** 5,
        path: google.maps.SymbolPath.CIRCLE,
        strokeWeight: this.mapZoom === 12 ? 2.5 : 3
      },
      clickable: !this.props.polygonReadonly,
      draggable: false
    }));
  };
  @computed get neighbourhoodCustomMarkers(): RenderablePolygonMarker[] {
    return (this.props.customPolygonMarkers || []).map((marker: CustomPolygonMarkerOption) => (marker.zoom && (this.mapZoom >= marker.zoom)) && ({
      ...this.markerOptions,
      id: marker.neighbourhoodId,
      position: this.getNeighbourhoodPositionById(marker.neighbourhoodId, { lat: .002 }),
      label: {
        ...mapPolygonMarkerLabelStyles,
        ...marker.styles,
        text: marker.content,
        className: "textPre"
      }
    })).filter(Boolean);
  };

  /** Polygons and shapes **/
  @computed get neighbourhoodPolygons(): RenderablePolygon[] {
    return this.neighbourhoods
    // .filter(neighbourhood => !isEmpty(neighbourhood.geometry))
    .filter(neighbourhood => (
      !isEmpty(neighbourhood.geometry)
      && neighbourhood.municipality === vancouver
    ))
    .map(neighbourhood => ({
      ...mapPolygonStyles,
      ...this.getPolygonFillProperties(neighbourhood),
      id: neighbourhood.id,
      clickable: !this.props.polygonReadonly,
      paths: getNeighbourhoodPaths(neighbourhood)
    }));
  };
  @computed get neighbourhoodRectangles(): RenderableRectangle[] {
    return this.neighbourhoods
    // .filter(neighbourhood => isEmpty(neighbourhood.geometry))
    .filter(neighbourhood => neighbourhood.municipality !== vancouver)
    .map(neighbourhood => ({
      ...mapPolygonStyles,
      ...this.getPolygonFillProperties(neighbourhood),
      id: neighbourhood.id,
      clickable: !this.props.polygonReadonly,
      bounds: getNeighbourhoodRectangleBounds(neighbourhood, this.mapZoom, this.props.zoom)
    }));
  };
  // @computed get neighbourhoodCircles(): RenderableCircle[] {
  //   return this.neighbourhoods
  //   // .filter(neighbourhood => isEmpty(neighbourhood.geometry))
  //   .filter(neighbourhood => neighbourhood.municipality !== Vancouver)
  //   .map(neighbourhood => ({
  //     strokeWeight: .1,
  //     ...this.getPolygonFillProperties(neighbourhood),
  //     id: neighbourhood.id,
  //     clickable: !this.props.polygonReadonly,
  //     center: getNeighbourhoodLocation(neighbourhood),
  //     radius: 700,
  //   }))
  // };

  @computed get localAreas(): LocalAreaValue[] {
    return this.neighbourhoods.map(n => ({
      neighbourhoodId: n.id,
      name: n.name
    }));
  };
  @computed get localAreaNames(): string[] {
    return this.localAreas.map(l => l.name);
  };
  @computed get selectedLocalAreas(): LocalAreaValue[] {
    return this.localAreas.filter(localArea => (
      this.selectedNeighbourhoodIds.includes(localArea.neighbourhoodId)
    ));
  };
  @computed get selectedLocalAreaNames(): string[] {
    return this.selectedLocalAreas.map(l => l.name);
  };

  @computed get mapObjects() {
    return {
      ...this.polygons,
      ...this.rectangles,
      ...this.circles
    };
  };

  @action initialize = () =>
    mcbBridge.isInitialized()
    .then(() => when(() => placesApi.initialized))
    .then(() => this.loading = false);

  @action setMap = map => {
    this.map = map;
    this.handleZoomChange(true);
    const { setMapGlobal } = this.props;
    setMapGlobal && setMapGlobal(map);
  };

  @action setPolygon = (id: number, polygon: MapsPolygon) => this.polygons[id] = polygon;

  @action setRectangle = (id: number, rectangle: MapsRectangle) => this.rectangles[id] = rectangle;

  @action setCircle = (id: number, circle: MapsCircle) => this.circles[id] = circle;

  @action removePolygon = (id: number) => !!this.polygons[id] && delete this.polygons[id];

  @action removeRectangle = (id: number) => !!this.rectangles[id] && delete this.rectangles[id];

  @action removeCircle = (id: number) => !!this.circles[id] && delete this.circles[id];

  getPolygonFillProperties = computedFn((neighbourhood: Neighbourhood) => {
    return {
      strokeColor: this.props.polygonColor,
      fillColor: this.props.polygonColor,
      fillOpacity: this.selectedNeighbourhoodIds.includes(neighbourhood.id) ? 0.5 : 0
    };
  });

  getCircleFillProperties = computedFn((neighbourhood: Neighbourhood) => {
    return {
      strokeColor: this.props.polygonColor,
      fillColor: this.props.polygonColor,
      fillOpacity: this.selectedNeighbourhoodIds.includes(neighbourhood.id) ? 0.5 : 0
    };
  });

  getNeighbourhoodPositionById = computedFn((neighbourhoodId: NeighbourhoodId, offset: { lat?: number, lng?: number }) => {
    const neighbourhood = this.neighbourhoods.find(n => n.id === neighbourhoodId);
    if (!neighbourhood) return null;
    return new google.maps.LatLng({
      lat: getNeighbourhoodLocation(neighbourhood).lat() + (offset?.lat || 0),
      lng: getNeighbourhoodLocation(neighbourhood).lng() + (offset?.lng || 0)
    });
  });

  @action handleZoomChange = (skipSetCenter?: boolean) => {
    const { onMapZoomChange } = this.props;
    this.mapZoom = this.map && this.map.getZoom();
    onMapZoomChange && onMapZoomChange(this.mapZoom);
    if (!skipSetCenter) this.handleMapMove();
  };

  @action handleMapTypeChange = () => {
    const { onMapTypeIdChange } = this.props;
    this.mapTypeId = this.map && this.map.getMapTypeId() as MapTypeId;
    onMapTypeIdChange && onMapTypeIdChange(this.mapTypeId);
  };

  @action handleMapMove = () => {
    const { onMapCenterChange } = this.props;
    this.mapCenter = this.map && this.map.getCenter();
    this.mapCenter && onMapCenterChange && onMapCenterChange(this.mapCenter);
  };

  @action handleMapObjectClick = (
    event: MapMouseEvent,
    neighbourhoodId: number,
    isPolygon?: boolean
  ) => {
    preventDefaultStopProp(event);
    const { onNeighbourhoodClick } = this.props;
    if (isPolygon) {
      if (!event.latLng) return;
      const lat = event.latLng.lat();
      const lng = event.latLng.lng();
      const containsLocation = google.maps.geometry.poly.containsLocation;
      neighbourhoodId = Number(Object.keys(this.polygons).find(id => (
        containsLocation({ lat, lng }, this.polygons[id])
      )));
    }
    // return console.log(neighbourhoodId);
    return onNeighbourhoodClick && onNeighbourhoodClick(neighbourhoodId);
  };

  @action handleLocalAreasChange = (names: string[]) => {
    const { onSelectedNeighbourhoodsUpdate } = this.props;
    const neighbourhoodIds =
      this.localAreas
      .map(l => names.includes(l.name) && l.neighbourhoodId)
      .filter(Boolean);
    return onSelectedNeighbourhoodsUpdate && onSelectedNeighbourhoodsUpdate(neighbourhoodIds);
  };

  @action handleMapClick = (event: MapMouseEvent) => {
    const { onCenterChange } = this.props;
    if (!event.latLng) return;
    return onCenterChange && onCenterChange(event.latLng);
  };

  @action openMobileMap = () => this.mobileMapIsOpen = true;

  @action closeMobileMap = () => this.mobileMapIsOpen = false;

  renderNeighbourhoodLabels = (isPreviewMap: boolean) => <Observer>{() => this.mapZoom >= 13 && <>
    {this.neighbourhoodLabelMarkers.map(marker => (
      <Marker
        key={marker.id}
        position={marker.position}
        options={marker}
        onClick={isPreviewMap
          ? this.openMobileMap
          : e => this.handleMapObjectClick(e, marker.id)}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodWithoutGeomLabels = (isPreviewMap: boolean) => <Observer>{() => this.mapZoom >= 13 && <>
    {this.neighbourhoodWithoutGeomLabelMarkers.map(marker => (
      <Marker
        key={marker.id}
        position={marker.position}
        options={marker}
        onClick={isPreviewMap
          ? this.openMobileMap
          : e => this.handleMapObjectClick(e, marker.id)}
      />
    ))}
  </>}</Observer>;

  // renderMunicipalitiesAsNeighbourhoods = () => <Observer>{() => this.mapZoom >= 12 && <>
  //   {this.municipalitiesAsNeighbourhoodLabelMarkers.map(marker => (
  //     <Marker
  //       key={marker.id}
  //       position={marker.position}
  //       options={marker}
  //     />
  //   ))}
  // </>}</Observer>;

  renderNeighbourhoodPolygons = (isPreviewMap: boolean) => <Observer>{() => <>
    {this.neighbourhoodPolygons.map(polygon => (
      <Polygon
        key={polygon.id}
        paths={polygon.paths}
        options={polygon}
        onLoad={p => this.setPolygon(polygon.id, p)}
        onUnmount={() => this.removePolygon(polygon.id)}
        onClick={isPreviewMap
          ? this.openMobileMap
          : e => this.handleMapObjectClick(e, polygon.id, true)}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodRectangles = (isPreviewMap: boolean) => <Observer>{() => this.mapZoom >= 13 && <>
    {this.neighbourhoodRectangles.map(rectangle => (
      <Rectangle
        key={rectangle.id}
        options={rectangle}
        onLoad={r => this.setRectangle(rectangle.id, r)}
        onUnmount={() => this.removeRectangle(rectangle.id)}
        onClick={isPreviewMap
          ? this.openMobileMap
          : e => this.handleMapObjectClick(e, rectangle.id)}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodCircles = (isPreviewMap: boolean) => <Observer>{() => this.mapZoom <= 12 && <>
    {this.neighbourhoodCircleMarkers.map(circle => (
      <Marker
        key={circle.id}
        position={circle.position}
        options={circle}
        onLoad={c => this.setCircle(circle.id, c)}
        onUnmount={() => this.removeCircle(circle.id)}
        onClick={isPreviewMap
          ? this.openMobileMap
          : e => this.handleMapObjectClick(e, circle.id)}
      />
    ))}
  </>}</Observer>;

  renderNeighbourhoodCustomLabels = () => <Observer>{() => <>
    {this.neighbourhoodCustomMarkers.map(marker => (
      <Marker
        key={marker.id}
        position={marker.position}
        options={marker}
        onClick={e => this.handleMapObjectClick(e, marker.id)}
      />
    ))}
  </>}</Observer>;

  renderCenterMarker = () => <Observer>{() => (
    <Marker
      position={this.props.center}
      options={{
        position: new google.maps.LatLng(this.props.center),
        clickable: false,
        // label: "Center",
        draggable: false
      }}
    />
  )}</Observer>;

  renderMapCenterMarker = () => <Observer>{() => (
    <Marker
      position={this.mapCenter}
      options={{
        position: new google.maps.LatLng(this.mapCenter),
        clickable: false,
        // label: "Center",
        draggable: false
      }}
    />
  )}</Observer>;

  renderLockedCenterMarker = () => <Observer>{() => (
    <StaticMarker style={{
      position: "absolute",
      left: "50%",
      top: "50%",
      transform: "translate(-50%, -50%)"
    }} />
  )}</Observer>;

  renderMapTitle = () => <Observer>{() => (
    <Typography variant="h6" className="mapTitle">
      {this.props.title}
    </Typography>
  )}</Observer>;

  renderNeighbourhoodSelector = () => <Observer>{() => (
    <MultipleNeighbourhoodSelector
      disabled={this.props.polygonReadonly}
      color={this.props.polygonColor}
      availableLocalAreaNames={this.localAreaNames}
      selectedLocalAreaNames={this.selectedLocalAreaNames}
      onSelectLocalAreaNames={this.handleLocalAreasChange}
    />
  )}</Observer>;

  renderPreviewMap = () => <Observer>{() => (
    ui.isMobile && <>
      {this.renderMapTitle()}
      <Typography className="scrollHint" gutterBottom>
        {this.props.helperMessage || "Tap on the map to select a neighbourhood."}
      </Typography>
      <GoogleMap
        onLoad={this.setMap}
        mapContainerStyle={{ width: "100%", height: "40vh" }}
        center={this.mapCenter || (this.props.center && {
          lat: this.props.center?.lat - .04,
          lng: this.props.center?.lng
        })}
        options={this.previewMapOptions}
        onClick={this.openMobileMap}
      >
        {this.props.showLockedCenterPin && this.renderMapCenterMarker()}
        {this.renderNeighbourhoodPolygons(true)}
        {this.renderNeighbourhoodRectangles(true)}
        {this.renderNeighbourhoodCircles(true)}
        {this.renderNeighbourhoodCustomLabels()}
      </GoogleMap>
    </>
  )}</Observer>;

  renderMainMap = () => <Observer>{() => (
    <GoogleMap
      mapContainerClassName="googleMap"
      onLoad={this.setMap}
      mapContainerStyle={{ width: "100%", height: "460px" }}
      center={this.props.center}
      zoom={this.props.zoom}
      options={this.mapOptions}
      onClick={this.handleMapClick}
      onZoomChanged={this.handleZoomChange}
      onMapTypeIdChanged={this.handleMapTypeChange}
      onDragEnd={this.handleMapMove}
    >
      {this.props.showCenterPin && this.renderCenterMarker()}
      {this.props.showLockedCenterPin && this.renderLockedCenterMarker()}
      {this.renderNeighbourhoodPolygons(false)}
      {this.renderNeighbourhoodRectangles(false)}
      {this.renderNeighbourhoodCircles(false)}
      {this.renderNeighbourhoodLabels(false)}
      {this.renderNeighbourhoodWithoutGeomLabels(false)}
      {/* {this.renderMunicipalitiesAsNeighbourhoods(false)} */}
      {this.renderNeighbourhoodCustomLabels()}
    </GoogleMap>
  )}</Observer>;

  renderMapComponent = () => <Observer>{() => <>
    {!this.props.hideTitle && this.renderMapTitle()}
    {/*{!this.props.hideSelector && this.renderNeighbourhoodSelector()}*/}
    {/*{!this.props.hideCenterField && this.renderCenterField()}*/}
    {this.renderMainMap()}
  </>}</Observer>;

  renderMobileComponent = () => <Observer>{() => (
    <AlertBase
      container={this.container}
      isOpen={this.mobileMapIsOpen}
      onClose={this.closeMobileMap}
      extraStyles={styles}
    >
      <McbComponentPastry>
        <Styled styles={styles}>
          <div className="mobileMapContainer">
            {ui.isMobile && (
              <Button
                className="modalClose"
                variant="contained"
                color="secondary"
                disableElevation
                onClick={this.closeMobileMap}
              >
                <Close />&nbsp;Back to Availability Finder
              </Button>
            )}
            <div className="flex column container">
              {this.props.MobileMapAdditionalComponentsTop}
              {this.renderMapComponent()}
              {this.props.MobileMapAdditionalComponentsBottom}
            </div>
          </div>
        </Styled>
      </McbComponentPastry>
    </AlertBase>
  )}</Observer>;

  render() {
    return <Styled styles={styles}>
      <div className="flex column align-items-center mapContainer">
        {this.loading ? (
          <div className="flex align-items-center justify-content-center loadingContainer">
            <CircularProgress size={30} />
          </div>
        ) : <>
          {/*{ui.isMobile ? this.renderPreviewMap() : this.renderMapComponent()}*/}
          {/*{ui.isMobile && this.renderMobileComponent()}*/}
          {this.renderMapComponent()}
        </>}
      </div>
    </Styled>;
  };
}

export default IGoogleMaps;