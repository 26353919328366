import React from "react";
import { observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";

export interface GridSelectionResetButtonProps {
  disabled?: boolean;
  size?: "small" | "medium" | "large";
  onClick: (event: any) => void;
}

export const GridSelectionResetButton: React.FC<GridSelectionResetButtonProps> =
  observer(({ disabled, size, onClick }) => {
    return (
      <Button
        variant="contained"
        size={size || "medium"}
        disabled={disabled}
        onClick={onClick}
        color="secondary"
        className="gridSelectionResetButton"
      >
        <Typography>
          Reset time selection
        </Typography>
      </Button>
    );
  });
