import React from "react";
import { Styled } from "direflow-component";
import { observer } from "mobx-react";
import { ToggleButton, ToggleButtonGroup } from "@material-ui/lab";

import styles from "./styles.css";
import { AvailabilityBlockContainerProps } from "../index";

export interface SetAvailableSingleBlockToggleProps extends Partial<AvailabilityBlockContainerProps> {
  isAvailable?: boolean;
  onSetAvailability?: (available: boolean) => void;
}

export const SetAvailableSingleBlockToggle: React.FC<SetAvailableSingleBlockToggleProps> =
  observer(({ isAvailable, onSetAvailability }) => {
    const handleSetAvailability = (event: any, value: any) => {
      const isSelected = value === "on";
      return onSetAvailability(isSelected);
    };
    return <Styled styles={styles}>
      <div>
        <ToggleButtonGroup
          size="small"
          value={isAvailable}
          exclusive
          onChange={handleSetAvailability}
          className="toggleButtonGroup"
        >
          <ToggleButton
            value="off"
            className={
              !isAvailable
                ? "toggleButtonSelectedItem"
                : "toggleButtonNonSelectedItem"
            }
          >
            NO
          </ToggleButton>
          <ToggleButton
            value="on"
            className={
              isAvailable
                ? "toggleButtonSelectedItem"
                : "toggleButtonNonSelectedItem"
            }
          >
            YES
          </ToggleButton>
        </ToggleButtonGroup>
      </div>
    </Styled>;
  });
