import { DireflowComponent } from "direflow-component";
import { initComponentWithCDM } from "../../client/boot";
import component from "./component";

export default initComponentWithCDM(() =>
  DireflowComponent.create({
    component,
    configuration: {
      tagname: "mcb-finder"
    },
  })
);