import React, { useEffect, useState } from "react";
import { Styled } from "direflow-component";
import { observer } from "mobx-react";
import styles from "./styles.css";
import { responsive } from "../../../config/styles/responsive";
import { WeeklyScheduleDesktopContainer, WeeklyScheduleDesktopContainerProps } from "../WeeklyScheduleDesktopContainer";
import { WeeklyScheduleContainerProps } from "../../../lib/types/propTypes";
import { WeeklyScheduleMobileContainer, WeeklyScheduleMobileContainerProps } from "../WeeklyScheduleMobileContainer";

export interface WeeklyScheduleGridContainerProps extends WeeklyScheduleContainerProps {}

export const WeeklyScheduleGridContainer: React.FC<WeeklyScheduleGridContainerProps> = observer(props => {
  const {
    isEditingDefaultNeighbourhoodSet,
    onDeselectAllBlocks,
    onEditDefaultNeighbourhoodSet,
    onOverrideMapNeighbourhoodIndex
  } = props;

  const [isMultiSetPopUpOpen, setOpenMultiSetPopUp] = useState(false);
  const handleOpenMultiSetPopUp = () => {
    setOpenMultiSetPopUp(true);
    isEditingDefaultNeighbourhoodSet && onEditDefaultNeighbourhoodSet();
  };
  const handleCloseMultiSetPopUp = () => {
    setOpenMultiSetPopUp(false);
  };
  const handleDeselectAllBlocks = () => {
    setOpenMultiSetPopUp(false);
    onDeselectAllBlocks();
  };
  useEffect(() => {
    if (!isMultiSetPopUpOpen) onOverrideMapNeighbourhoodIndex(null);
  }, [isMultiSetPopUpOpen, onOverrideMapNeighbourhoodIndex]);

  const passthroughProps: WeeklyScheduleMobileContainerProps | WeeklyScheduleDesktopContainerProps = {
    ...props,
    isMultiSetPopUpOpen,
    onDeselectAllBlocks: handleDeselectAllBlocks,
    onOpenMultiSetPopUp: handleOpenMultiSetPopUp,
    onCloseMultiSetPopUp: handleCloseMultiSetPopUp,
  };

  return (
    <Styled styles={styles}>
      <div>
        {responsive.deviceDimension.isMobileIntermediate ? (
          <WeeklyScheduleMobileContainer {...passthroughProps} />
        ) : (
          <WeeklyScheduleDesktopContainer {...passthroughProps} />
        )}
      </div>
    </Styled>
  );
});
