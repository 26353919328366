import React from "react";
import { observer } from "mobx-react";
import { Styled } from "direflow-component";
import { Grid, Typography } from "@material-ui/core";

import styles from "./styles.css";
import { showWeekOfDay } from "../../../../config/constants";
import { GridBlock } from "../../GridBlock";
import { WeeklyScheduleGridContainerProps } from "../../WeeklyScheduleGridContainer";

export interface WeeklyScheduleTimeOfDayGridProps extends Partial<WeeklyScheduleGridContainerProps> {
  tod: number;
}

export const WeeklyScheduleTimeOfDayGrid: React.FC<WeeklyScheduleTimeOfDayGridProps> =
  observer(
    ({
       tod,
       neighbourhoodSets,
       getBlockIsSelected,
       getBlockIsAvailable,
       getBlockColor,
       getBlockNeighbourhoodSetIndex,
       getBlockCoverText,
       getBlockShowDeselectButton,
       onSelectBlock,
       onDeselectBlock,
       onSetAvailability,
       onOpenMultiSetPopUp,
       onBlockCoverTextClick
     }) => {
      return (
        <Styled styles={styles}>
          <div>
            <Grid
              container
              item
              spacing={2}
              wrap="nowrap"
              className="weeklyScheduleTimeOfDayGridContainer"
            >
              {showWeekOfDay.map((dayName, dow) => {
                return <React.Fragment key={dow}>
                  <Grid container direction="column">
                    <Grid
                      container
                      item
                      justifyContent="center"
                      alignItems="center"
                    >
                      <Grid item>
                        <Typography className="font-xs">
                          {dayName}
                        </Typography>
                      </Grid>
                    </Grid>
                    <Grid item>
                      <GridBlock
                        key={dayName}
                        dow={dow}
                        tod={tod}
                        neighbourhoodSets={neighbourhoodSets}
                        getBlockIsSelected={getBlockIsSelected}
                        getBlockIsAvailable={getBlockIsAvailable}
                        getBlockColor={getBlockColor}
                        getBlockNeighbourhoodSetIndex={getBlockNeighbourhoodSetIndex}
                        getBlockCoverText={getBlockCoverText}
                        getBlockShowDeselectButton={getBlockShowDeselectButton}
                        onSelectBlock={onSelectBlock}
                        onDeselectBlock={onDeselectBlock}
                        onSetAvailability={onSetAvailability}
                        onOpenMultiSetPopUp={onOpenMultiSetPopUp}
                        onBlockCoverTextClick={onBlockCoverTextClick}
                      />
                    </Grid>
                  </Grid>
                </React.Fragment>
              })}
            </Grid>
          </div>
        </Styled>
      );
    }
  );
