import { AxiosResponse } from "axios";
import { Coordinate, CoordinateArr, CoordinateSet, GeocodingResponse, Neighbourhood } from "../lib/types/caf/geoDataTypes";
import { isEmpty, numNeighbourhoodNameLines } from "./helpers";
import { api } from "../client/mcb-bridge/api";
import { endpointConfig } from "../config/api";
import { serverConfig } from "../config/api/base";

export const getCityCoordinates = async (city: string): Promise<AxiosResponse<GeocodingResponse>> => {
  return api.GET({
    headers: serverConfig.defaultHeaders,
    endpoint: endpointConfig.geocoding(city)
  })
};

export const findCityCoordinates = async (
  city: string
): Promise<Coordinate> => {
  const formatCity = city.split(" ").join("+");
  return getCityCoordinates(formatCity)
  .then(response => {
    // const { data } = response;
    // return ((data.results || [])[0] || {}).geometry?.location as Coordinate;
    const data = (response.data || [])[0];
    const lat = Number((data || {}).lat);
    const lng = Number((data || {}).lon);
    return { lat, lng };
  });
};

export const getNeighbourhoodPaths = (neighbourhood: Neighbourhood) => {
  const { coordinates } = neighbourhood.boundaryData?.geometry;
  let paths = [];
  if (isEmpty(coordinates)) return paths;
  if (neighbourhood.boundaryData.geometry.type === "MultiPolygon") {
    const geometry = neighbourhood.geometry as unknown as CoordinateSet[][][];
    paths = geometry.map(coordinateSet =>
      coordinateSet[0].map((coords: CoordinateSet) =>
        !isEmpty(coords)
        && !isNaN(coords.x)
        && !isNaN(coords.y)
        && ({ lat: coords.y, lng: coords.x })
      ).filter(Boolean)  
    );
  } else {
    paths = coordinates[0].map((coords: CoordinateArr) =>
      !isEmpty(coords)
      && !isNaN(coords[0])
      && !isNaN(coords[1])
      && ({ lat: coords[1], lng: coords[0] })
    ).filter(Boolean);
  }
  return paths;
};

export const getNeighbourhoodLocation = (neighbourhood: Neighbourhood): google.maps.LatLng => {
  const { location } = neighbourhood;
  const lat = Number(location.coordinates[0]);
  const lng = Number(location.coordinates[1]);
  return (!isNaN(lat) && !isNaN(lng)) && new google.maps.LatLng({
    lat, lng
  });
};

const rad = x => x * Math.PI / 180;

export const getDistanceInKms = (
  viewportCentre: google.maps.LatLng,
  screenCorner: google.maps.LatLng
): number => {
  let R = 6378137; // Earth’s mean radius in meter
  let dLat = rad(viewportCentre.lat() - screenCorner.lat());
  let dLong = rad(viewportCentre.lng() - screenCorner.lng());
  let a = Math.sin(dLat / 2) * Math.sin(dLat / 2) +
    Math.cos(rad(viewportCentre.lat())) * Math.cos(rad(screenCorner.lat())) *
    Math.sin(dLong / 2) * Math.sin(dLong / 2);
  let c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1 - a));
  let d = R * c;
  return d/1000;
};

export const getNeighbourhoodRectangleBounds = (
  neighbourhood: Neighbourhood,
  zoom: number,
  defaultZoom: number
) => {
  const lat = Number(neighbourhood?.location?.coordinates[0]);
  const lng = Number(neighbourhood?.location?.coordinates[1]);
  const numOfNameLines = numNeighbourhoodNameLines(neighbourhood);
  // console.log(numOfNameLines);
  const zoomRatio = defaultZoom / zoom;
  const defaultZoomRatio = defaultZoom / 17;
  const heightFactor = 0.0012 * numOfNameLines * zoomRatio ** 4;
  const defaultHeightFactor = 0.0012 * numOfNameLines * defaultZoomRatio ** 4;
  const lengthFactor = 0.0008 *  neighbourhood.name.length / numOfNameLines * zoomRatio ** 4; //eslint-disable-line
  const defaultLengthFactor = 0.0008 * neighbourhood.name.length / numOfNameLines * defaultZoomRatio ** 4; //eslint-disable-line
  return {
    north: zoom <= 17 ? lat + heightFactor : lat + defaultHeightFactor,
    south: zoom <= 17 ? lat - heightFactor : lat - defaultHeightFactor,
    east:  zoom <= 17 ? neighbourhood.name.length < 6 ? lng + 1.5 * lengthFactor : lng + lengthFactor : lng + defaultLengthFactor,
    west:  zoom <= 17 ? neighbourhood.name.length < 6 ? lng - 1.5 * lengthFactor : lng - lengthFactor : lng - defaultLengthFactor,
  };
};