import "@webcomponents/webcomponentsjs/webcomponents-bundle.js";
import "@webcomponents/webcomponentsjs/custom-elements-es5-adapter.js";
import { DireflowComponent } from "direflow-component";
import component from "./component";
import { domRef } from "../../client/ref";

export default DireflowComponent.create({
  component,
  configuration: {
    tagname: "caf-initializer",
    useShadow: false
  },
  properties: {
    getInstance: () => domRef
  },
  plugins: [
    {
      name: "polyfill-loader",
      options: {
        use: {
          sd: false,
          ce: false,
          adapter: false
        }
      }
    }
  ]
});