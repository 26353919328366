import React from "react";
import { observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";

export interface ClearSelectedBlocksButtonProps {
  disabled?: boolean;
  onClick: (event: any) => void;
}

export const ClearSelectedBlocksButton: React.FC<ClearSelectedBlocksButtonProps> =
  observer(({ disabled, onClick }) => {
    return (
      <Button
        variant="contained"
        size="medium"
        disabled={disabled}
        onClick={onClick}
        color="secondary"
        className="clearSelectionButton"
      >
        <Typography>
          CLOSE ALL TIME BLOCKS
        </Typography>
      </Button>
    );
  });
