import React from "react";
import DomRef, { initDomRef } from "../../client/ref";
import { getQueryParameters } from "../../utils/helpers";
import { isCrawler } from "../../config/env";
import { observer } from "mobx-react";
import McbComponentPastry from "../../components/McbComponentPastry";

const emptyRenderer = { render: () => null } as CafInitializer;

export interface CafInitializerProps {}

@observer
class CafInitializer extends React.Component<CafInitializerProps> {
  domRef: DomRef;
  constructor(props) {
    super(props);
    // Prevent multiple instances.
    const existsSelf = document.getElementsByTagName("caf-initializer");
    if (existsSelf && existsSelf.length > 1) return emptyRenderer;
    if (this.domRef) return emptyRenderer;
    if ((getQueryParameters(window.location.search) || {})["noWc"] === "1") return emptyRenderer;
    if (isCrawler) return emptyRenderer;
    this.domRef = initDomRef(new DomRef());
  }

  render() {
    return <McbComponentPastry />;
  }
}

export default CafInitializer;