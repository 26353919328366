import { mcbBridge } from "./index";
import { KeyCodeBinding, KeyCodeBindingCombos, UIAlertOptions, UIErrorOptions } from "../../lib/types/miscTypes";
import { Controller } from "../../lib/controller";

interface UIStore {}

export interface UI extends Controller<UIStore> {
  placeholderAvatarUrl: string;

  defaultDuration: number;
  defaultToastDuration: number;

  errorTitle: string;
  error: UIErrorOptions["err"];
  errorButtons: UIErrorOptions["buttons"];

  alertOpen: boolean;
  alertTitle: string;
  alertMessage: any;
  alertButtons: UIErrorOptions["buttons"];
  alertBackdropDismiss: boolean;

  isMobile: boolean;

  showError: (options: UIErrorOptions) => void;

  dismissError: () => void;

  showAlert: (options: UIAlertOptions) => void;

  dismissAlert: () => void;

  bindKeyCode: (eventType: "onKeyUp" | "onKeyDown", binding: KeyCodeBinding, combo?: KeyCodeBindingCombos) => ({ [key: string]: Function });

  comingSoon: () => void;
}

export let ui: UI = {} as UI;
export const initUi = () => mcbBridge.isInitialized().then(() => ui = mcbBridge.getUi());