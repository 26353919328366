import React from "react";
import { observer } from "mobx-react";
import McbComponentPastry from "../../components/McbComponentPastry";
import withJssMap from "../../components/withJssMap";
import ITestMap from "../../components/ITestMap";

@observer
class TestMap extends React.Component {
  render() {
    return (
     <McbComponentPastry>
       <ITestMap />
     </McbComponentPastry>
    );
  }
}

export default withJssMap(TestMap);
