import React from "react";
import { observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";
import { stateCtrl } from "../../../../../client/mcb-bridge/state";

export interface ApplyButtonProps {
  disabled?: boolean;
  onClick: (event: any) => void;
}

export const ApplyButton: React.FC<ApplyButtonProps> = observer(
  ({ disabled, onClick }) => {
    const setButtonRefToStateCtrl = button => stateCtrl.multipleBlocksApplyButton = button;
    return (
      <Button
        ref={setButtonRefToStateCtrl}
        variant="contained"
        size="large"
        disabled={disabled}
        onClick={onClick}
        color="secondary"
        className={disabled ? "applyDisabledButton" : "applyButton"}
      >
        <Typography>
          APPLY
        </Typography>
      </Button>
    );
  }
);
