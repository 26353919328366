import { observer } from "mobx-react";
import { computed, observable, when } from "mobx";
import React from "react";
import { PlacesAutocompleteResult } from "../../lib/types/dataTypes";
import { placesApi } from "../../client/mcb-bridge/places";
import { PlacesAutocompleteResultLimiter } from "../../lib/types/formTypes";
import { isEmpty, isEqual, preventDefaultStopProp, randomString } from "../../utils/helpers";
import { FormControl, FormHelperText, IconButton, InputAdornment, InputLabel, OutlinedInput, TextField } from "@material-ui/core";
import { mcbBridge } from "../../client/mcb-bridge";
import { Close } from "@material-ui/icons";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import { api } from "../../client/mcb-bridge/api";
import { serverConfig } from "../../config/api/base";
import { endpointConfig } from "../../config/api";

export interface NeighbourhoodCenterSelectorProps extends Omit<React.ComponentProps<typeof TextField>, "value"> {
  mapOptions?: google.maps.MapOptions;
  autocompleteOptions?: google.maps.places.AutocompleteOptions;
  biasCenter?: boolean;
  limitTo?: PlacesAutocompleteResultLimiter;
  value?: PlacesAutocompleteResult;
  onPositionChange?: (value: PlacesAutocompleteResult) => void;
}

// TODO: Merge and bridge from web-components.
@observer
class NeighbourhoodCenterSelector extends React.Component<NeighbourhoodCenterSelectorProps> {
  @observable initialized: boolean;
  @observable input: HTMLInputElement;

  @observable value: PlacesAutocompleteResult | string = "";

  @computed get displayValue(): string {
    return typeof this.value === "string"
      ? this.value
      : typeof this.value === "object"
      ? this.value?.formattedAddress || ""
      : "";
  };

  constructor(props) {
    super(props);
    const { value } = this.props;
    this.value = this.reverseCode(value) as PlacesAutocompleteResult;
    this.fixPacContainerOutOfBound();
  }

  componentDidUpdate(prevProps: Readonly<NeighbourhoodCenterSelectorProps>) {
    if (!isEqual(prevProps.value, this.props.value)) {
      this.value = this.reverseCode(this.props.value) as PlacesAutocompleteResult;
    }
    // if (!this.props.readonly && !this.initialized) this.initGooglePlaces();
  }

  inputRef = (ref: HTMLInputElement) => {
    if (ref) this.input = ref;
    return this.initGooglePlaces(this.input);
  };

  setMapCircleCenter = (autocomplete: google.maps.places.Autocomplete, options: google.maps.CircleOptions) => {
    const circle = new google.maps.Circle(options);
    return autocomplete.setBounds(circle.getBounds());
  };

  setCenterUsingLocation = async (autocomplete: google.maps.places.Autocomplete) => {
    const position = await placesApi.getGeolocation(true).catch(console.warn);
    if (!position) return;
    const circle: google.maps.CircleOptions = {
      center: {
        lat: position.coords.latitude,
        lng: position.coords.longitude
      },
      radius: position.coords.accuracy
    };
    return this.setMapCircleCenter(autocomplete, circle);
  };

  setCenterUsingOptions = async (autocomplete: google.maps.places.Autocomplete) =>
    this.setMapCircleCenter(autocomplete, this.props.mapOptions);

  initGooglePlaces = async (inputElm: HTMLInputElement) => {
    await mcbBridge.isInitialized();
    if (this.props.disabled) return;
    if (this.initialized) return;
    if (!inputElm) return;
    await when(() => placesApi.initialized);
    const autocomplete = new google.maps.places.Autocomplete(inputElm, this.props.autocompleteOptions);
    if (this.props.biasCenter) this.setCenterUsingLocation(autocomplete).catch(console.warn);
    if (this.props.mapOptions) this.setCenterUsingOptions(autocomplete).catch(console.warn);
    autocomplete.addListener("place_changed", e => {
      const place = autocomplete.getPlace();
      if (!place.place_id) return; // Prevent enter-key clearing places data.
      const value = placesApi.parseAutocompleteResult(place, this.props.limitTo);
      return this.props.onPositionChange && this.props.onPositionChange(value);
    });
    return this.initialized = true;
  };

  handleChange = (event: any) => {
    preventDefaultStopProp(event);
    const { target } = event;
    if (!target) return;
    const { value } = target;
    this.value = value;
    if (isEmpty(value)) return this.handleClear(null);
  };

  handleClear = (event: any) => {
    preventDefaultStopProp(event);
    this.value = "";
    this.props.onPositionChange(null);
  };

  fixPacContainerOutOfBound = () => {
    const pacContainer = () => Array.from(document.getElementsByClassName("pac-container"))[0] as HTMLElement;
    if (!pacContainer()) return setTimeout(this.fixPacContainerOutOfBound, 2000);
    const setPacContainerOffset = () => {
      const elm = pacContainer();
      if (!elm) return;
      elm.style.zIndex = "999999";
      // const top = Number((elm.style.top || "").toString().replace("px", ""));
      // if (isNaN(top)) return;
      // if ((top + elm.clientHeight) < (window.innerHeight - 50)) return;
      // return elm.style.marginTop = `-${elm.clientHeight + 50}px`;
    };
    new ResizeObserver(setPacContainerOffset).observe(pacContainer());
  };

  reverseCode = (value: PlacesAutocompleteResult) => {
    if (isEmpty(value)) return value;
    const { lat, lng, formattedAddress } = value || {};
    const isPureCoords = !isNaN(Number(lat)) && !isNaN(Number(lng)) && !formattedAddress;
    if (!isPureCoords) return value;
    api.GET({
      endpoint: endpointConfig.reverse_geocoding(lat, lng),
      headers: serverConfig.defaultHeaders
    })
    .then(response => response.data)
    .then(data => {
      const formattedAddress = (data || {}).formattedAddress;
      this.value = {
        ...data, // OSM first then Google
        ...value,
        formattedAddress
      };
      this.props.onPositionChange && this.props.onPositionChange(this.value as PlacesAutocompleteResult);
    })
    .catch(console.error);
    return "...";
  };

  render() {
    const {
      className,
      helperText,
      placeholder,
      label,
      value,
      disabled
    } = this.props;

    return <Styled styles={styles}>
      <FormControl
        className={`neighbourhoodCenterSelector ${className || ""}`}
        variant="outlined"
      >
        {label && <InputLabel htmlFor={this.input?.id}>{label}</InputLabel>}
        <OutlinedInput
          inputRef={this.inputRef}
          id={`neighbourhoodCenterSelector_${randomString()}`}
          value={this.displayValue}
          placeholder={placeholder}
          disabled={disabled}
          onChange={this.handleChange}
          endAdornment={
            !isEmpty([value, this.value].filter(Boolean)) && <InputAdornment position="end">
              <IconButton
                aria-label="toggle password visibility"
                onClick={this.handleClear}
              >
                <Close />
              </IconButton>
            </InputAdornment>
          }
        />
        <FormHelperText>
          {helperText}
        </FormHelperText>
      </FormControl>
    </Styled>;
  }
}

export default NeighbourhoodCenterSelector;