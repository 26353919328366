import React from "react";
import { observer } from "mobx-react";
import { Grid, Typography } from "@material-ui/core";
import { Styled } from "direflow-component";

import styles from "./styles.css";
import { timeOfDayDescription, timeOfDayName } from "../../../../config/constants";

export interface TimeOfDayHeadingProps {
  tod: number;
}

export const TimeOfDayHeading: React.FC<TimeOfDayHeadingProps> = observer(
  ({ tod }) => {
    const timeOfDayText = tod >= 0 ? <>{timeOfDayName[tod]} anytime between <span style={{ whiteSpace: "nowrap" }}>{timeOfDayDescription[tod]}</span></> : "";
    return (
      <Styled styles={styles}>
        <Grid item className="timeOfDayHeading">
          <Typography className="font-s">
            {timeOfDayText}
          </Typography>
        </Grid>
      </Styled>
    );
  }
);
