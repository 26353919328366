/**
 * Boot file to ensure all CDM controllers are whacked into the memory on initialization of any web-components.
 */

import { observable, when } from "mobx";
import { env } from "../config/env";
import { Controller } from "../lib/controller";
import { initUi, UI } from "./mcb-bridge/ui";
import { initMcbBridge, McbBridge } from "./mcb-bridge";
import { initPlacesApi, PlacesApi } from "./mcb-bridge/places";
import { Api, initApi } from "./mcb-bridge/api";
import { initMcbSessionCtrl, McbSessionController } from "./mcb-bridge/session";
import { Client, initClient } from "./mcb-bridge/client";
import { initMcbSearchCtrl, McbSearchController } from "./mcb-bridge/search";
import { isEmpty, whenFulfill } from "../utils/helpers";
import { initStateCtrl, State } from "./mcb-bridge/state";
import { initTopicCtrl, TopicController } from "./mcb-bridge/topic";

class BootLoader {
  @observable ready: boolean = false;
  controllers: {
    mcbBridge: McbBridge;

    client: Client;
    api: Api;
    stateCtrl: State;
    topicCtrl: TopicController;
    places: PlacesApi;
    ui: UI;

    mcbSessionCtrl: McbSessionController;
    mcbSearchCtrl: McbSearchController;
  };

  constructor() {
    this.controllers = {} as BootLoader["controllers"];
    this.controllers.mcbBridge = initMcbBridge(new McbBridge());

    this.controllers.api = initApi();
    this.controllers.client = initClient();
    this.controllers.stateCtrl = initStateCtrl();
    this.controllers.topicCtrl = initTopicCtrl();
    this.controllers.ui = initUi();
    this.controllers.places = initPlacesApi();
    this.controllers.mcbSessionCtrl = initMcbSessionCtrl();
    this.controllers.mcbSearchCtrl = initMcbSearchCtrl();

    this._assignGlobal();
    this._waitReady().catch(console.error);
  }

  private readonly _assignGlobal = () => {
    if (env.match(/prod/ig)) return;
    for (const controller in this.controllers) {
      if (this.controllers[controller] instanceof Controller) {
        (window as any)[controller] = this.controllers[controller];
      }
    }
  };

  private readonly _waitReady = () =>
    Promise.all(
      Object.values(this.controllers)
      .map((controller) => (
        whenFulfill(() => !isEmpty(controller), 20)
        .then(controller.storage?.isReady)
      ))
    )
    .then(this.controllers.mcbBridge.isInitialized)
    .then(() => (this.ready = true));
}

const readyState: { bootLoader: BootLoader } = observable({
  bootLoader: undefined,
});

const setCDMReadyState = () => (readyState.bootLoader = new BootLoader());

const bootstrapIsReady = () =>
  readyState.bootLoader && readyState.bootLoader.ready;

const initComponentWithCDM = async (createComponent) =>
  when(bootstrapIsReady).then(createComponent);

export { setCDMReadyState, initComponentWithCDM };
