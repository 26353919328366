import { mcbBridge } from "./index";
import {
  CheckUniqueResult,
  EntityProfileDuoDTO,
  Group,
  GroupType,
  Member,
  OAuth2Data,
  PersonalProfile,
  Profile,
  User,
  UserLoginData
} from "../../lib/types/dataTypes";
import { Controller } from "../../lib/controller";
import { DataParser } from "../../lib/parser";
import { ClientUniqueIdentifiers } from "../../lib/types/formTypes";

export interface ClientStore {
  id: string;
  oid: string;
  user: User;
  groupTypes: GroupType[];
  groups: Group[];
}

export interface Client extends Controller<ClientStore> {
  initialized: boolean;
  hasError: boolean;

  groupParser: DataParser<EntityProfileDuoDTO<Group>>;
  memberParser: DataParser<EntityProfileDuoDTO<Member>>;

  readonly id: string;
  readonly user: User;
  readonly oauth: OAuth2Data;
  readonly groupTypes: GroupType[];
  readonly groups: Group[];
  readonly nonDefaultGroups: Group[];
  readonly members: Member[];

  readonly clientId: string;
  readonly userId: User["id"];
  readonly defaultMember: Member;
  readonly defaultGroup: Group;
  readonly defaultProfile: Profile<PersonalProfile>;

  readonly allEmailAddresses: string[];

  readonly credentialReady: boolean;
  readonly isLoggedIn: boolean;
  readonly isVisitor: boolean;

  isLoggedInAndReady: () => Promise<void>;


  /**
   * Client control
   */
  isMaintenance: () => Promise<boolean>;

  loginAndStoreUser: (input: UserLoginData<string>) => Promise<User>;

  logout: () => Promise<void>;

  logoutReload: () => Promise<void>;


  /**
   * Api data getters
   */
  getGroupById: (id: Group["id"]) => Promise<Group>;

  getGroupsByTypeId: (typeId: Group["typeId"]) => Promise<Group[]>;

  getMemberById: (id: Member["id"]) => Promise<Member>;

  getMembersByGroupId: (groupId: Group["id"]) => Promise<Member[]>;

  getProfileById: (id: Profile["id"]) => Promise<Profile>;

  checkUniqueIdentifier: (type: ClientUniqueIdentifiers, value: string) => Promise<CheckUniqueResult>;


  /**
   * Local data updaters
   */
  updateGroup: (data: Group) => Group;

  updateMember: (data: Member) => Member;

  updateProfile: (data: Profile) => Profile;


  /**
   * Local data finders
   */
  findGroupById: (groupId: number) => Group;

  findGroupTypeById: (groupTypeId: number) => GroupType;

  findGroups: (query: ((group: Group) => boolean)) => Group[];

  findVisibleGroups: () => Group[];

  findMembers: (query: ((member: Member) => boolean), limit?: "group" | "topic") => Member[];

  findMyMemberByGroupId: (groupId: number) => Member;

  findMyMemberInGroup: (group: Group) => Member;
}

export let client: Client = {} as Client;
export const initClient = () => mcbBridge.isInitialized().then(() => client = mcbBridge.getClient());