import React from "react";
import { observer } from "mobx-react";
import { AlertBase } from "../AlertBase";
import { Button, IconButton, Typography } from "@material-ui/core";
import ReactPlayer from "react-player";
import McbComponentPastry from "../McbComponentPastry";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import { ui } from "../../client/mcb-bridge/ui";
import { Close } from "@material-ui/icons";

export interface TrainingVideoModalProps {
  isModalOpen?: boolean;
  onModalClose?: (event: any) => void;
  onLoadData?: (event: any) => void;
  url?: string;
}

const TrainingVideoModal: React.FC<TrainingVideoModalProps> = observer(
  ({ isModalOpen, onModalClose, url }) => (
    <AlertBase
      isOpen={isModalOpen}
      onClose={onModalClose}
    >
      <McbComponentPastry>
        <Styled styles={styles}>
          <div className="trainingVideoModal">
            {ui.isMobile && (
              <Button
                className="modalClose"
                variant="contained"
                color="secondary"
                disableElevation
                onClick={onModalClose}
              >
                <Close/>&nbsp;Back to Availability Finder
              </Button>
            )}
            {!ui.isMobile && (
              <IconButton
                color="secondary"
                className="absolute closeButton"
                onClick={onModalClose}
              >
                <Close />
              </IconButton>
            )}
            <Typography color="secondary" className="titleVideo">
              Caregiver Availability Finder Training Video
            </Typography>
            <div className="videoWrapper">
              <ReactPlayer
                className="react-player"
                url={url}
                playing
                playsinline
                controls
                loop={false}
                muted={false}
              />
            </div>
          </div>
        </Styled>
      </McbComponentPastry>
    </AlertBase>
  ));

export default TrainingVideoModal;