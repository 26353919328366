import { JSONOfPlacesAutocompleteResult, PlacesAutocompleteResultLimiter } from "../../lib/types/formTypes";
import { PlacesAutocompleteResult } from "../../lib/types/dataTypes";
import { mcbBridge } from "./index";
import { Controller } from "../../lib/controller";

export interface PlacesStore {}

export interface PlacesApi extends Controller<PlacesStore> {
  google: typeof google;

  initialized: boolean;

  checkDomTag: () => HTMLScriptElement;

  getApiKey: () => string;

  getGeolocation: (enableHighAccuracy?: boolean) => any;

  parseAutocompleteResult: (place: google.maps.places.PlaceResult, limitTo?: PlacesAutocompleteResultLimiter) => PlacesAutocompleteResult;

  parseManualResult: (data: PlacesAutocompleteResult, limitTo?: PlacesAutocompleteResultLimiter) => PlacesAutocompleteResult;

  parseManualFullAddress: (result: PlacesAutocompleteResult) => string;

  parseLimitedAddress: (result: PlacesAutocompleteResult) => string;

  getFormattedAddress: (value: JSONOfPlacesAutocompleteResult | PlacesAutocompleteResult, limitTo?: PlacesAutocompleteResultLimiter) => string;

  getEnteredLocation: () => PlacesAutocompleteResult;

  setEnteredLocation: (location: PlacesAutocompleteResult) => PlacesAutocompleteResult;
}

export let placesApi: PlacesApi = {} as PlacesApi;
export const initPlacesApi = () => mcbBridge.isInitialized().then(() => placesApi = mcbBridge.getPlacesApi());