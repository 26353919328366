import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";
import { ui } from "../../../../client/mcb-bridge/ui";

export interface SettingMultipleBlocksHeadingProps {
  blockName?: string;
}

export const SettingMultipleBlocksHeading: React.FC<SettingMultipleBlocksHeadingProps> =
  observer(({ blockName }) => {
    return <div className="flex column justify-content-center align-items-center settingMultipleBlocksHeading">
      <Typography variant={ui.isMobile ? "h5" : "h4"} color="secondary">
        AVAILABLE
      </Typography>
      <Typography className="font-xs" gutterBottom>
        FOR&nbsp;{blockName || "SELECTED TIME BLOCKS"}
      </Typography>
    </div>;
  });
