import { setCDMReadyState } from "./boot";

/**
 * Ref Class to expose the methods to be consumed.
 */
export default class DomRef {
  readonly identifier = "mycarebase-finder";

  constructor() { this._ready() }

  private _ready = () => setCDMReadyState();
};

export let domRef = {} as DomRef;
export const initDomRef = constructor => domRef = constructor;

export const globalCafHook: { [key: string]: Function } = (window as any).cafHooks;