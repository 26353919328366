import React from "react";
import { observer } from "mobx-react";
import { Typography } from "@material-ui/core";

import { numOfPossibleTimeSlotsInADay } from "../../../config/constants";
import { TimeOfDayContainer } from "./TimeOfDayContainer";
import { SettingMultipleBlocksContainer } from "../SettingMultipleBlocksContainer";
import { WeeklyScheduleContainerProps } from "../../../lib/types/propTypes";
import styles from "./styles.css";
import { Styled } from "direflow-component";
import { WeeklyScheduleMobileToolbar } from "../WeeklyScheduleMobileToolbar";

export interface WeeklyScheduleMobileContainerProps extends WeeklyScheduleContainerProps {
  isMultiSetPopUpOpen?: boolean;
}

export const WeeklyScheduleMobileContainer: React.FC<WeeklyScheduleMobileContainerProps> =
  observer(
    ({
       selectedBlocks,
       neighbourhoodSets,
       getBlockIsSelected,
       getBlockIsAvailable,
       getBlockColor,
       getBlockNeighbourhoodSetIndex,
       getBlockCoverText,
       getBlockShowDeselectButton,
       hideToolbar,
       isMultiSetPopUpOpen,
       onSelectBlock,
       onDeselectBlock,
       onDeselectAllBlocks,
       onSetAvailability,
       onSetMultipleBlocksAvailability,
       onSetMultipleBlocksNeighbourhoodSet,
       onOpenMultiSetPopUp,
       onCloseMultiSetPopUp,
       onOverrideMapNeighbourhoodIndex,
       onBlockCoverTextClick
     }) => {
      return <Styled styles={styles}>
        <div className="weeklyScheduleMobileContainer">
          <div className="flex column weeklyScheduleMobileContainerGrid">
            {Array.from(new Array(numOfPossibleTimeSlotsInADay)).map((nul, tod) => {
              if (tod < 0) return null;
              return <TimeOfDayContainer
                key={tod}
                tod={tod}
                neighbourhoodSets={neighbourhoodSets}
                getBlockIsSelected={getBlockIsSelected}
                getBlockIsAvailable={getBlockIsAvailable}
                getBlockColor={getBlockColor}
                getBlockNeighbourhoodSetIndex={getBlockNeighbourhoodSetIndex}
                getBlockCoverText={getBlockCoverText}
                getBlockShowDeselectButton={getBlockShowDeselectButton}
                onSelectBlock={onSelectBlock}
                onDeselectBlock={onDeselectBlock}
                onSetAvailability={onSetAvailability}
                onOpenMultiSetPopUp={onOpenMultiSetPopUp}
                onBlockCoverTextClick={onBlockCoverTextClick}
              />
            })}

            <SettingMultipleBlocksContainer
              isOpen={isMultiSetPopUpOpen}
              selectedBlocks={selectedBlocks}
              neighbourhoodSets={neighbourhoodSets}
              getBlockIsAvailable={getBlockIsAvailable}
              getBlockNeighbourhoodSetIndex={getBlockNeighbourhoodSetIndex}
              onDeselectAllBlocks={onDeselectAllBlocks}
              onSetMultipleBlocksAvailability={onSetMultipleBlocksAvailability}
              onSetMultipleBlocksNeighbourhoodSet={onSetMultipleBlocksNeighbourhoodSet}
              onOverrideMapNeighbourhoodIndex={onOverrideMapNeighbourhoodIndex}
              handleCloseMultiSetPopUp={onCloseMultiSetPopUp}
            />
          </div>

          <Typography className="scrollHint">
            ↓ Scroll down the blocks for more availability options
          </Typography>

          {!hideToolbar && (
            <WeeklyScheduleMobileToolbar
              selectedBlocks={selectedBlocks}
              onDeselectAllBlocks={onDeselectAllBlocks}
              onOpenMultiSetPopUp={onOpenMultiSetPopUp}
            />
          )}
        </div>
      </Styled>;
    }
  );