import React from "react";
import { observer } from "mobx-react";
import { Grid } from "@material-ui/core";
import { Styled } from "direflow-component";
import styles from "./styles.css";
import { numOfPossibleTimeSlotsInADay, showWeekOfDay } from "../../../config/constants";
import { GridBlock } from "../GridBlock";
import { WeeklyScheduleContainerProps } from "../../../lib/types/propTypes";
import { SettingMultipleBlocksContainer } from "../SettingMultipleBlocksContainer";
import { WeeklyScheduleDesktopToolbar } from "../WeeklyScheduleDesktopToolbar";

export interface WeeklyScheduleDesktopContainerProps extends WeeklyScheduleContainerProps {
  isMultiSetPopUpOpen?: boolean;
}

export const WeeklyScheduleDesktopContainer: React.FC<WeeklyScheduleDesktopContainerProps> =
  observer(
    ({
       selectedBlocks,
       neighbourhoodSets,
       getBlockIsSelected,
       getBlockIsAvailable,
       getBlockColor,
       getBlockNeighbourhoodSetIndex,
       getBlockCoverText,
       getBlockShowDeselectButton,
       hideToolbar,
       isMultiSetPopUpOpen,
       isEditingDefaultNeighbourhoodSet,
       onSelectBlock,
       onDeselectBlock,
       onDeselectAllBlocks,
       onSetAvailability,
       onSetMultipleBlocksAvailability,
       onSetMultipleBlocksNeighbourhoodSet,
       onEditDefaultNeighbourhoodSet,
       onSaveDefaultNeighbourhoodSet,
       onOpenMultiSetPopUp,
       onCloseMultiSetPopUp,
       onOverrideMapNeighbourhoodIndex,
       onBlockCoverTextClick
     }) => {
      return (
        <Styled styles={styles}>
          <div>
            <Grid container className="relative weeklyScheduleDesktopContainer">
              <div className="relative weeklyScheduleDesktopContainerGrid">
                {Array.from(new Array(numOfPossibleTimeSlotsInADay + 1)).map((nul, tod) => { // +1 for starting column
                  tod = tod - 1;
                  return <React.Fragment key={tod}>
                    <Grid
                      container
                      item
                      spacing={0}
                      className="weeklyScheduleDesktopContainerRow"
                      wrap="nowrap"
                    >
                      {showWeekOfDay.concat("").map((el, dow) => { // concat("") for header row
                        dow = dow - 1;
                        return <GridBlock
                          key={el}
                          dow={dow}
                          tod={tod}
                          neighbourhoodSets={neighbourhoodSets}
                          getBlockIsSelected={getBlockIsSelected}
                          getBlockIsAvailable={getBlockIsAvailable}
                          getBlockColor={getBlockColor}
                          getBlockNeighbourhoodSetIndex={getBlockNeighbourhoodSetIndex}
                          getBlockCoverText={getBlockCoverText}
                          getBlockShowDeselectButton={getBlockShowDeselectButton}
                          onSelectBlock={onSelectBlock}
                          onDeselectBlock={onDeselectBlock}
                          onSetAvailability={onSetAvailability}
                          onOpenMultiSetPopUp={onOpenMultiSetPopUp}
                          onBlockCoverTextClick={onBlockCoverTextClick}
                        />
                      })}
                    </Grid>
                  </React.Fragment>;
                })}
              </div>

              <SettingMultipleBlocksContainer
                isOpen={isMultiSetPopUpOpen}
                selectedBlocks={selectedBlocks}
                neighbourhoodSets={neighbourhoodSets}
                getBlockIsAvailable={getBlockIsAvailable}
                getBlockNeighbourhoodSetIndex={getBlockNeighbourhoodSetIndex}
                onDeselectAllBlocks={onDeselectAllBlocks}
                onSetMultipleBlocksAvailability={onSetMultipleBlocksAvailability}
                onSetMultipleBlocksNeighbourhoodSet={onSetMultipleBlocksNeighbourhoodSet}
                onOverrideMapNeighbourhoodIndex={onOverrideMapNeighbourhoodIndex}
                handleCloseMultiSetPopUp={onCloseMultiSetPopUp}
              />

              {!hideToolbar && (
                <WeeklyScheduleDesktopToolbar
                  selectedBlocks={selectedBlocks}
                  isEditingDefaultNeighbourhoodSet={isEditingDefaultNeighbourhoodSet}
                  onDeselectAllBlocks={onDeselectAllBlocks}
                  onOpenMultiSetPopUp={onOpenMultiSetPopUp}
                  onEditDefaultNeighbourhoodSet={onEditDefaultNeighbourhoodSet}
                  onSaveDefaultNeighbourhoodSet={onSaveDefaultNeighbourhoodSet}
                />
              )}
            </Grid>
          </div>
        </Styled>
      );
    }
  );
