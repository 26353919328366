import React from "react";
import { observer } from "mobx-react";
import { Button, Typography } from "@material-ui/core";

export interface CancelButtonProps {
  disabled?: boolean;
  showClose?: boolean;
  onClick: (event: any) => void;
}

export const CancelButton: React.FC<CancelButtonProps> = observer(
  ({ disabled, showClose, onClick }) => {
    return (
      <Button
        variant="contained"
        size="large"
        disabled={disabled}
        onClick={onClick}
        color="secondary"
        className="cancelButton"
      >
        <Typography>
          {showClose ? "CLOSE" : "CANCEL"}
        </Typography>
      </Button>
    );
  }
);
