import { Group, Member, Profile, User } from "../../lib/types/dataTypes";
import { JSONString, KeyValuePairs } from "../../lib/types/miscTypes";
import { Controller } from "../../lib/controller";
import { isEmpty, whenFulfill } from "../../utils/helpers";
import { UI } from "./ui";
import { PlacesApi } from "./places";
import { Neighbourhood } from "../../lib/types/caf/geoDataTypes";
import { Api } from "./api";
import { McbSessionController } from "./session";
import { Client } from "./client";
import { McbSearchController } from "./search";
import { State } from "./state";
import { LoginBoxOptions } from "../../lib/types/stateTypes";
import { FormLegacy } from "./form.legacy";
import { TypeClass } from "../../lib/types/formTypes.legacy";
import { TypeClassField } from "../../lib/types/formTypes";
import { TopicController } from "./topic";

type AuthEventHandler = (param?: any) => any;

export interface McbBridge extends Controller {
  readonly identifier: string;

  openWebApp: () => void;

  isUserLoggedIn: () => boolean;

  loginEvents: ({ name: string; handler: (param?: any) => any })[];
  logoutEvents: ({ name: string; handler: (param?: any) => any })[];

  addLoginEventListener: (name: string, handler: AuthEventHandler) => void;
  removeLoginEventHandler: (name: string) => void;

  /** Logout usually follows with refresh, not recommended to use **/
  addLogoutEventListener: (name: string, handler: AuthEventHandler) => void;
  removeLogoutEventHandler: (name: string) => void;

  waitLoginFlowToFinish: () => Promise<true>;

  setLoginBoxVisible: (isVisible: boolean, options?: LoginBoxOptions) => void;

  getCurrentLoggedInUser: () => User;

  getCurrentLoggedInUserGroups: () => Group[];

  getCurrentLoggedInUserMembers: () => Member[];

  getProfileById: (profileId: number) => Promise<Profile>;

  updateProfileDataById: (profileId: number, data: KeyValuePairs) => Promise<Profile>;

  getNeighbourhoodsData: (lat: number, lng: number, radius: number) => Promise<Neighbourhood[]>;

  assistEnglishSelection: (form: FormLegacy) => void;

  getApi: () => Api;

  getClient: () => Client;

  getUi: () => UI;

  getPlacesApi: () => PlacesApi;

  getStateCtrl: () => State;

  getTopicCtrl: () => TopicController;

  getMcbSessionCtrl: () => McbSessionController;

  getMcbSearchCtrl: () => McbSearchController;

  genFormLegacy: (
    form: TypeClass["id"] | JSONString | TypeClassField[],
    inputData?: JSONString | KeyValuePairs,
    formFlags?: KeyValuePairs<boolean>
  ) => FormLegacy;
}

export class McbBridge extends Controller {
  constructor() {
    super();
    this.initialize();
  }

  initialize = () => {
    const mcbInitializer = document.querySelector("mcb-initializer") as any;
    whenFulfill(() => !!mcbInitializer?.getInstance && !isEmpty(mcbInitializer?.getInstance()?.identifier), 50)
    .then(() => Object.assign(this, mcbInitializer?.getInstance()));
  };

  isInitialized = () => whenFulfill(() => mcbBridge.identifier, 50);

  isReady = () => whenFulfill(() => !!this.isUserLoggedIn, 50);
}

export let mcbBridge: McbBridge = {} as McbBridge;
export const initMcbBridge = (constructor: McbBridge) => mcbBridge = constructor;