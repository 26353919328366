import { observer } from "mobx-react";
import { isEmpty } from "../../../utils/helpers";
import { Grid } from "@material-ui/core";
import { ClearSelectedBlocksButton } from "../ClearSelectedBlocksButton";
import { SetMultipleBlocksButton } from "../SetMultipleBlocksButton";
import React from "react";
import styles from "./styles.css";
import { WeeklyScheduleContainerProps } from "../../../lib/types/propTypes";
import { Styled } from "direflow-component";

export interface WeeklyScheduleMobileToolbarProps extends Partial<WeeklyScheduleContainerProps> {}

export const WeeklyScheduleMobileToolbar: React.FC<WeeklyScheduleMobileToolbarProps> = observer(
  ({
     selectedBlocks,
     onDeselectAllBlocks,
     onOpenMultiSetPopUp
   }) => (
     <Styled styles={styles}>
       <Grid
         container
         item
         justifyContent="center"
         alignItems="center"
         className="weeklyScheduleMobileContainerButtons"
         spacing={2}
       >
         {!isEmpty(selectedBlocks) && (
           <Grid item>
             <ClearSelectedBlocksButton
               onClick={onDeselectAllBlocks}
             />
           </Grid>
         )}
         {selectedBlocks.length >= 2 && (
           <Grid item>
             <SetMultipleBlocksButton
               onClick={onOpenMultiSetPopUp}
             />
           </Grid>
         )}
       </Grid>
     </Styled>
  ));